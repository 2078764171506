import React, { useState } from 'react';
import { HiMenu } from 'react-icons/hi';
import { MdHelpOutline, MdAccountCircle } from 'react-icons/md';
import { FaUserCog } from 'react-icons/fa';
import { links } from '../components/Data'; // External data

import { LogOutIcon } from 'lucide-react';

const Sidebar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(null);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    const handleTabClick = (id) => {
        setActiveTab(id);
    };

    return (
        <>
            {/* Sidebar for Desktop */}
            <div className="fixed inset-y-0 left-0 w-64 bg-darkCoffee p-6 border-r border-gray-700 hidden md:block font-serif">
                <div className="mb-6">
                    <a href="/" className="text-2xl font-bold text-white  hover:text-orange-400">
                        SPACEMASTER
                    </a>
                </div>
                <nav className="flex-1 space-y-3">
                    {links.map(({ id, url, text, icon }) => (
                        <a
                            key={id}
                            href={url}
                            onClick={() => handleTabClick(id)}
                            className={`flex items-center px-4 py-2 mt-2 text-white hover:bg-orange-400 hover:text-gray-800 rounded-md transition-colors ${activeTab === id ? 'text-orange-300' : ''
                                }`}
                        >
                            <span className="text-lg">{icon}</span>
                            <span className="ml-4">{text}</span>
                        </a>
                    ))}
                </nav>
                <hr className="border-1 border-gray-700 mt-3 font-serif" />
                <div className="mt-6 space-y-3">

                    <a href="/admin-dashboard" className="flex items-center px-4 py-2 rounded-md text-white hover:bg-orange-400 hover:text-gray-800">
                        <FaUserCog className="w-5 h-5" />
                        <span className="ml-4">Admin</span>
                    </a>
                    <a href="/help-center" className="flex items-center px-4 py-2 rounded-md text-white hover:bg-orange-400 hover:text-gray-800">
                        <MdHelpOutline className="w-5 h-5" />
                        <span className="ml-4">Help Center</span>
                    </a>
                    <a href="/" className="flex items-center px-4 py-2 rounded-md text-white hover:bg-orange-400 hover:text-gray-800">
                        <LogOutIcon className="w-5 h-5" />
                        <span className="ml-4">Logout</span>
                    </a>

                    <div className="flex flex-wrap items-center cursor-pointer border-t border-gray-700 py-2">
                        <img src="https://readymadeui.com/team-2.webp" className="w-10 h-10 rounded-md border-2 border-white mt-6" />
                        <div className="ml-4">
                            <p className="text-sm text-white font-semibold mt-6">John Doe</p>
                            <p className="text-xs text-orange-300 mt-0.5">Active free account</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Bottom Navigation for Mobile */}
            <div className="fixed bottom-0 left-0 right-0 bg-darkCoffee shadow-lg md:hidden z-50 mt-6 font-serif">
                <nav className="flex justify-around py-3">
                    {links.map(({ id, url, icon, text }) => (
                        <a
                            key={id}
                            href={url}
                            onClick={() => handleTabClick(id)}
                            className={`flex flex-col items-center text-white ${activeTab === id ? 'text-orange-300' : ''
                                }`}
                        >
                            <span className="text-lg">{icon}</span>
                            <span className="text-xs mt-1">{text}</span>
                        </a>
                    ))}
                    <button
                        onClick={toggleMenu}
                        className="flex flex-col items-center text-white"
                    >
                        <HiMenu className="w-6 h-6" />
                        <span className="text-xs mt-1">Menu</span>
                    </button>
                </nav>
            </div>

            {/* Dropdown Menu for Mobile */}
            {isMenuOpen && (
                <div className="fixed bottom-16 left-0 right-0 bg-darkCoffee text-white shadow-lg rounded-md p-6 space-y-4 font-serif">

                    <a
                        href="/admin-dashboard"
                        onClick={() => handleTabClick('admin')}
                        className={`flex items-center px-4 py-2 rounded-md text-white hover:bg-gray-200 hover:text-gray-800 ${activeTab === 'admin' ? 'text-orange-300' : ''}`}
                    >
                        <MdAccountCircle className="w-5 h-5" />
                        <span className="ml-4">Admin</span>
                    </a>
                    <a
                        href="/help-center"
                        onClick={() => handleTabClick('help')}
                        className={`flex items-center px-4 py-2 rounded-md text-white hover:bg-gray-200 hover:text-gray-800 ${activeTab === 'help' ? 'text-orange-300' : ''}`}
                    >
                        <MdHelpOutline className="w-5 h-5" />
                        <span className="ml-4">Help Center</span>
                    </a>
                    <a
                        href="/"
                        onClick={() => handleTabClick('logout')}
                        className={`flex items-center px-4 py-2 rounded-md text-white hover:bg-gray-200 hover:text-gray-800 ${activeTab === 'logout' ? 'text-orange-300' : ''}`}
                    >
                        <LogOutIcon className="w-5 h-5" />
                        <span className="ml-4">Logout</span>
                    </a>
                </div>
            )}
        </>
    );
};

export default Sidebar;
