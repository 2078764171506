import React from 'react';
import { Phone, Mail, MapPin, Clock, User, PenSquare } from 'lucide-react';

const ContactForm = () => {
    return (
        <div>
            <section className="relative isolate py-16 sm:py-24 lg:py-32 font-serif">
                {/* Background */}
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{
                        backgroundImage: `linear-gradient(to bottom, 
            rgba(59, 48, 44, 0.9), 
            rgba(59, 48, 44, 0.9), 
            rgba(59, 48, 44, 0.7), 
            rgba(59, 48, 44, 0.8)),  
            url('/assets/images/door.jpg')`,
                        filter: "blur(0px)", // Adds the blur effect to the image.
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                ></div>

                <div className="relative max-w-7xl mx-auto px-7 lg:px-8 flex items-center justify-center h-full">
                    {/* "Get in Touch" Section */}
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center text-left lg:text-left">
                        {/* Content Section */}

                        {/* Repeat Content Section */}
                        <div className=''>
                            <h1 className="text-6xl text-orange-200 mt-12 font-black">Get in touch</h1>

                            <p className="text-lg text-white leading-relaxed mt-8">
                                Our team of experts is dedicated to designing sustainable, high-quality prefab timber-framed houses tailored to your lifestyle.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <div className='bg-orange-50'>
                <div className="min-h-screen  py-16 px-6 font-serif">
                    {/* Contact Info Cards */}


                    {/* Main Contact Section */}
                    <div className=" p-1 md:p-10 ">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                            {/* Left Section */}
                            <div className="bg-gradient-to-r from-orange-100 to-white p-6 shadow-sm">
                                <p className="text-orange-500 font-medium mb-3"> — Contact Us —</p>
                                <h2 className="text-5xl  mb-6 text-gray-800">
                                    Talk To Our Experts <br />
                                </h2>
                                <p className="text-gray-600 mb-8 leading-relaxed">
                                    We’re here to help you bring your ideas to life. Get in touch to discuss
                                    your project requirements and expectations.
                                </p>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                    {[
                                        { icon: Phone, label: "Phone Number", detail: "+91-1234567890" },
                                        { icon: Mail, label: "Email Address", detail: "info@spacemaster.com" },
                                        { icon: MapPin, label: "Our Location", detail: "Banglore , Karnataka" },
                                        { icon: Clock, label: "Opening Hour", detail: "Mon - Fri: 09am - 07pm" },
                                    ].map((item, index) => (
                                        <div
                                            key={index}
                                            className="flex items-center space-y-6    space-x-6"
                                        >
                                            <div className="bg-orange-200 rounded-full p-4">
                                                <item.icon className="text-orange-400 w-6 h-6" />
                                            </div>
                                            <div>
                                                <h3 className="font-bold text-lg text-gray-800">{item.label}</h3>
                                                <p className="text-gray-600">{item.detail}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Right Section - Form */}
                            <div className="">
                                <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">Get in Touch </h2>
                                <form className="space-y-6">
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                        {/* Name Input */}
                                        <div className="relative">
                                            <input
                                                type="text"
                                                placeholder="Name"
                                                className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-orange-300 pl-12"
                                            />
                                            <User className="w-6 h-6 text-gray-400 absolute left-4 top-4" />
                                        </div>
                                        {/* Phone Input */}
                                        <div className="relative">
                                            <input
                                                type="tel"
                                                placeholder="Phone Number"
                                                className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-orange-300 pl-12"
                                            />
                                            <Phone className="w-6 h-6 text-gray-400 absolute left-4 top-4" />
                                        </div>
                                    </div>
                                    {/* Email Input */}
                                    <div className="relative">
                                        <input
                                            type="email"
                                            placeholder="Email Address"
                                            className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-orange-300 pl-12"
                                        />
                                        <Mail className="w-6 h-6 text-gray-400 absolute left-4 top-4" />
                                    </div>
                                    {/* Work Inquiries */}

                                    {/* Project Details */}
                                    <div className="relative">
                                        <textarea
                                            placeholder="Project Details"
                                            rows={4}
                                            className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-orange-300 pl-12"
                                        ></textarea>
                                        <PenSquare className="w-6 h-6 text-gray-400 absolute left-4 top-4" />
                                    </div>
                                    {/* Submit Button */}
                                    <button
                                        type="submit"
                                        className="w-full bg-orange-400 text-white py-4 rounded-lg hover:bg-orange-500 transition-all duration-300"
                                    >
                                        Book Appointment
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg overflow-hidden shadow-lg">
                    <iframe
                        title="Google Map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248849.84916296526!2d77.6309395!3d12.9539974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1670c9b44e6d%3A0xf8dfc3e8517e4fe0!2sBengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1732957893918!5m2!1sen!2sin"
                        width="100%"
                        height="350"
                        className="border-0"
                        allowFullScreen=""
                        loading="lazy"
                    ></iframe>
                </div>
            </div>
        </div>


    );
};

export default ContactForm;
