// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import Footer from './components/Footer';
import AboutUs from './pages/About';
import ContactUs from './pages/Contact';
import FloorPlans from './pages/FloorPlans';
import CostEstimator from './pages/CostEstimator';
import ContactFormModal from './pages/ContactFormModal';
import OurProject from './pages/OurProject';
import Notfound from './pages/Notfound';
import Login from './pages/Login';


import Dashboard from './components/Dashboard';
import Enquiries from './pages/Enquiries';
import Enquiry from './components/Enquiry';
import Customer from './components/Customer';
import Project1 from './components/Project1';
import Packages3 from './components/Packages3';
import Packages from './pages/Packages';




function App() {
  return (
    <Router>
      <div className="app-container">


        <main className="content">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Header />
                  <Home />
                  <Footer />
                </>
              }
            />
            <Route
              path="/about-us"
              element={
                <>
                  <Header />
                  <AboutUs />
                  <Footer />
                </>
              }
            />
            <Route
              path="/contact-us"
              element={
                <>
                  <Header />
                  <ContactUs />
                  <Footer />
                </>
              }
            />
            <Route
              path="/floor-plans"
              element={
                <>
                  <Header />
                  <FloorPlans />
                  <Footer />
                </>
              }
            />
            <Route
              path="/cost-estimator"
              element={
                <>
                  <Header />
                  <CostEstimator />
                  <Footer />
                </>
              }
            />
            <Route
              path="/our-project"
              element={
                <>
                  <Header />
                  <OurProject />
                  <Footer />
                </>
              }
            />
            <Route
              path="/login-page"
              element={
                <>

                  <Login />
                  <Footer />
                </>
              }
            />
            <Route
              path="/package"
              element={
                <>
                  <Header />
                  <Packages />
                  <Footer />
                </>
              }
            />

            {/* dahsboard */}
            <Route
              path="/spacemaster-dashboard"
              element={
                <>

                  <Dashboard />

                </>
              }
            />
            <Route
              path="/spacemaster-enquires"
              element={
                <>

                  <Enquiry />

                </>
              }
            />

            <Route
              path="/spacemaster-customer"
              element={
                <>

                  <Customer />

                </>
              }
            />
            <Route
              path="/spacemaster-project"
              element={
                <>

                  <Project1 />

                </>
              }
            />

            <Route
              path="/spacemaster-packages"
              element={
                <>

                  <Packages3 />

                </>
              }
            />

            <Route path="*" element={<Notfound />} />
          </Routes>
        </main>


        <ContactFormModal />
      </div>
    </Router>
  );
}

export default App;
