import React, { useState } from 'react';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { TrendingUp, TrendingDown, Users, BookOpen, Star, ArrowRight } from 'lucide-react';
import { Tooltip, Legend, } from 'recharts';
import { Layout, Pen, Code, Rocket, MoreVertical, FileText, Trash2 } from 'lucide-react';
import { FaUserCog, FaUsers } from 'react-icons/fa';

const mockData = [
    { id: 1, name: "John Doe", phone: "1234567890", email: "john@example.com", location: "New York", description: "Lorem ipsum", status: "Resolved" },
    { id: 2, name: "Jane Smith", phone: "0987654321", email: "jane@example.com", location: "Los Angeles", description: "Lorem ipsum", status: "Pending" },
    { id: 3, name: "Mary Johnson", phone: "1122334455", email: "mary@example.com", location: "Chicago", description: "Lorem ipsum", status: "In Progress" },
    { id: 4, name: "James Brown", phone: "2233445566", email: "james@example.com", location: "Houston", description: "Lorem ipsum", status: "Resolved" },
    { id: 5, name: "Patricia Davis", phone: "3344556677", email: "patricia@example.com", location: "Miami", description: "Lorem ipsum", status: "Pending" },
    { id: 6, name: "Robert Miller", phone: "4455667788", email: "robert@example.com", location: "Seattle", description: "Lorem ipsum", status: "Resolved" },
    { id: 7, name: "Linda Wilson", phone: "5566778899", email: "linda@example.com", location: "Denver", description: "Lorem ipsum", status: "In Progress" },
    { id: 8, name: "Michael Moore", phone: "6677889900", email: "michael@example.com", location: "Dallas", description: "Lorem ipsum", status: "Resolved" },
    { id: 9, name: "Elizabeth Taylor", phone: "7788990011", email: "elizabeth@example.com", location: "Phoenix", description: "Lorem ipsum", status: "Pending" },
    { id: 10, name: "David Anderson", phone: "8899001122", email: "david@example.com", location: "Austin", description: "Lorem ipsum", status: "Resolved" }
];

const DashboardContent = () => {



    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage] = useState(5); // Number of rows per page
    const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to the first page on search
    };

    const sortedData = React.useMemo(() => {
        if (sortConfig.key) {
            return [...mockData].sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === "asc" ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === "asc" ? 1 : -1;
                }
                return 0;
            });
        }
        return mockData;
    }, [mockData, sortConfig]);

    const filteredData = sortedData.filter(
        (enquiry) =>
            enquiry.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            enquiry.phone.includes(searchTerm) ||
            enquiry.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            enquiry.location.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginatedData = filteredData.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    const handleSort = (key) => {
        setSortConfig((prev) => ({
            key,
            direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
        }));
    };

    const totalPages = Math.ceil(filteredData.length / rowsPerPage);




    const registrationData = Array.from({ length: 12 }, (_, i) => ({
        name: `Day ${i + 1}`,
        paid: Math.floor(Math.random() * 50) + 20,
        free: Math.floor(Math.random() * 30) + 10,
    }));

    return (
        <>
            <div className="mb-6 font-serif">
                <h1 className="text-2xl font-bold text-gray-800 dark:text-white text-left">Admin Dashboard</h1>
                <div className="flex items-center text-sm text-gray-500">
                    <span className="mx-2">›</span>
                    <span className="text-orange-500 flex items-center gap-2">
                        {/* Dashboard Icon */}
                        <FaUserCog />
                        Dashboard
                    </span>
                </div>
            </div>
            <div class="grid grid-cols-1 gap-4 px-4 mt-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 sm:px-8">
                <div class="flex items-center bg-white border rounded-sm overflow-hidden shadow">
                    <div class="p-4 bg-orange-400"><svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-white" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z">
                        </path>
                    </svg></div>
                    <div class="px-4 text-gray-700">
                        <h3 class="text-sm tracking-wider">Total Enquiries</h3>
                        <p class="text-3xl">768</p>
                    </div>
                </div>
                <div class="flex items-center bg-white border rounded-sm overflow-hidden shadow">
                    <div class="p-4 bg-green-400"><svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-white" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2">
                        </path>
                    </svg></div>
                    <div class="px-4 text-gray-700">
                        <h3 class="text-sm tracking-wider">Total Projects</h3>
                        <p class="text-3xl">265</p>
                    </div>
                </div>
                <div class="flex items-center bg-white border rounded-sm overflow-hidden shadow">
                    <div class="p-4 bg-indigo-400"><svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-white" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z">
                        </path>
                    </svg></div>
                    <div class="px-4 text-gray-700">
                        <h3 class="text-sm tracking-wider">Total Customers</h3>
                        <p class="text-3xl">334</p>
                    </div>
                </div>
                <div class="flex items-center bg-white border rounded-sm overflow-hidden shadow">
                    <div class="p-4 bg-red-400"><svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-white" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4">
                        </path>
                    </svg></div>
                    <div class="px-4 text-gray-700">
                        <h3 class="text-sm tracking-wider">Packages Sold</h3>
                        <p class="text-3xl">12%</p>
                    </div>
                </div>
            </div>













            {/* enquiries */}
            <div className="p-6 min-h-screen font-serif">
                <h2 className="text-2xl  text-gray-800 mb-4">Enquiries Management</h2>

                {/* Search Bar and See All Enquiries Button */}
                <div className="flex justify-between items-center mb-4">
                    <input
                        type="text"
                        placeholder="Search enquiries..."
                        className="px-4 py-2 border rounded-lg focus:outline-none w-2/3"
                        value={searchTerm}
                        onChange={handleSearch}
                    />
                    <button
                        className="px-4 py-1 bg-orange-400 text-white rounded-lg hover:bg-amber-700 text-sm"
                        onClick={() => console.log("See All Enquiries clicked")}
                    >
                        Show All
                    </button>
                </div>

                {/* Table */}
                <div className="bg-white shadow-lg rounded-lg overflow-x-auto custom-scrollbar">
                    <table className="min-w-full bg-white">
                        <thead>
                            <tr>
                                <th
                                    className="py-2 px-4 text-left text-sm font-semibold text-gray-700 border-b cursor-pointer"
                                    onClick={() => handleSort("name")}
                                >
                                    Name
                                </th>
                                <th
                                    className="py-2 px-4 text-left text-sm font-semibold text-gray-700 border-b cursor-pointer"
                                    onClick={() => handleSort("phone")}
                                >
                                    Phone
                                </th>
                                <th
                                    className="py-2 px-4 text-left text-sm font-semibold text-gray-700 border-b cursor-pointer"
                                    onClick={() => handleSort("email")}
                                >
                                    Email
                                </th>
                                <th
                                    className="py-2 px-4 text-left text-sm font-semibold text-gray-700 border-b cursor-pointer"
                                    onClick={() => handleSort("location")}
                                >
                                    Location
                                </th>
                                {/* <th
                                    className="py-2 px-4 text-left text-sm font-semibold text-gray-700 border-b cursor-pointer"
                                    onClick={() => handleSort("description")}
                                >
                                    Description
                                </th> */}
                                <th
                                    className="py-2 px-4 text-left text-sm font-semibold text-gray-700 border-b cursor-pointer"
                                    onClick={() => handleSort("status")}
                                >
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedData.map((enquiry) => (
                                <tr key={enquiry.id} className="hover:bg-gray-100">
                                    <td className="py-2 px-4 text-gray-700 border-b">{enquiry.name}</td>
                                    <td className="py-2 px-4 text-gray-700 border-b">{enquiry.phone}</td>
                                    <td className="py-2 px-4 text-gray-700 border-b">{enquiry.email}</td>
                                    <td className="py-2 px-4 text-gray-700 border-b">{enquiry.location}</td>
                                    {/* <td className="py-2 px-4 text-gray-700 border-b">{enquiry.description}</td> */}
                                    <td className="py-2 px-4 text-gray-700 border-b">
                                        <span
                                            className={`px-3 py-1 rounded-full text-xs font-medium ${enquiry.status === "Resolved"
                                                ? "bg-green-100 text-green-700"
                                                : enquiry.status === "Pending"
                                                    ? "bg-yellow-100 text-yellow-700"
                                                    : "bg-blue-100 text-blue-700"
                                                }`}
                                        >
                                            {enquiry.status}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Pagination */}
                <nav aria-label="Page navigation example" className="mt-4">
                    <ul className="inline-flex -space-x-px text-sm">
                        <li>
                            <a
                                href="#"
                                className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-white bg-darkCoffee border border-e-0 border-gray-300 rounded-s-lg  "
                                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </a>
                        </li>
                        {[...Array(totalPages)].map((_, index) => (
                            <li key={index}>
                                <a
                                    href="#"
                                    onClick={() => setCurrentPage(index + 1)}
                                    className={`flex items-center justify-center px-3 h-8 leading-tight ${currentPage === index + 1
                                        ? 'text-white  border border-gray-300'
                                        : 'text-white bg-white border border-gray-300 hover:bg-gray-100 hover:text-white'
                                        } dark:bg-orange-400   dark:hover:bg-gray-700 `}
                                >
                                    {index + 1}
                                </a>
                            </li>
                        ))}
                        <li>
                            <a
                                href="#"
                                className="flex items-center justify-center px-3 h-8 leading-tight text-white bg-darkCoffee border-gray-300 rounded-e-lg  "
                                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>

        </>

    );
};

export default DashboardContent;
