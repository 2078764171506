import React from 'react';


const Homeconst = () => {
    const packages = [
        { title: 'Basic Package', price: '1,810' },
        // { title: 'Classic Package', price: '1,940' },
        { title: 'Premium Package', price: '2,250' },
        { title: 'Royal Package', price: '2,400' }
    ];

    const sections1 = [
        'Designs & Drawings',
        'Structure',
        'Kitchen',
        'Bathroom',
        'Doors & Windows',
        'Painting',
        'Flooring',
        'Electrical',
        'Miscellaneous'
    ];
    return (
        <section className="bg-orange-50 py-12 min-h-screen ">

            <div className="max-w-7xl mx-auto px-4">
                <div className="text-center mb-12">


                    <div className=" text-amber-700 font-medium ">— Packages —</div>

                    <h1 className='text-5xl font-serif mb-6'> Home Construction Packages</h1>


                    <p className="text-gray-600">Find the best home construction packages.*</p>
                    <div className="mb-4">
                        <span className="text-gray-600">Currently showing for </span>
                        <div className="relative inline-block">
                            <select
                                className="text-orange-500 font-medium inline-flex items-center bg-white border rounded-md px-2 py-1 focus:outline-none focus:ring-1 focus:ring-orange-300"
                                onChange={(e) => console.log(e.target.value)} // handle change
                            >
                                <option value="bengaluru">BENGALURU</option>
                                <option value="delhi">DELHI</option>
                                <option value="mumbai">MUMBAI</option>
                                <option value="chennai">CHENNAI</option>
                            </select>

                        </div>
                    </div>
                </div>

                {/* Grid for 4 items in a row */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 ">

                    {packages.map((pkg) => (
                        <div key={pkg.title} className="bg-gray-50 rounded-lg shadow-lg p-6 flex flex-col h-full border border-orange-300">
                            {/* Package Header */}
                            <div className="bg-orange-200 -mx-6 -mt-6 p-4 rounded-t-lg border border-orange-300">
                                <h3 className="text-darkCoffee  text-xl font-semibold p-2 ">
                                    {pkg.title} - ₹{pkg.price}/sq.ft <span className="text-sm">(Incl. GST)</span>
                                </h3>
                            </div>

                            {/* Package Sections */}
                            <div className="flex-grow">
                                {sections1.map((section) => (
                                    <div key={section} className="border-b py-1 text-sm">
                                        <div className="flex justify-between items-center">
                                            <span className="text-gray-800">{section}</span>
                                            <button className="text-3xl text-darkCoffee hover:text-gray-600">+</button>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* Get in Touch Button */}
                            <div className="mt-6 text-center">
                                <p className="text-gray-600 mb-4 text-sm">Get in touch with us!</p>
                                <button className="bg-orange-300 text-darkCoffee px-12 py-4 rounded-full hover:bg-orange-400 shadow-lg transition-transform transform hover:scale-105 inline-flex items-center gap-3">
                                    LET'S BUILD
                                    <svg
                                        className="w-5 h-5"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M9 5l7 7-7 7"
                                        />
                                    </svg>
                                </button>

                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>


    );
};

export default Homeconst;
