import React, { useState } from 'react';
import { Phone, Mail, User, Briefcase, ChevronDown, } from 'lucide-react';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


export default function OurProject() {
    const [activeTab, setActiveTab] = useState("COMPLETED");
    const [projectCounts, setProjectCounts] = useState({
        COMPLETED: 3,
        ACTIVE: 3,
        "NEAR COMPLETION": 3,
    });

    const projects = [
        { id: "CRN52902", image: "/assets/images/1.jpg", location: "Bannerghatta, Bengaluru", sqft: "3000 sq.ft.", type: "G + 1", package: "Classic", status: "COMPLETED" },
        { id: "CRN559", image: "/assets/images/1f.jpg", location: "Hennur Main Road, Bengaluru", sqft: "2446 sq.ft.", type: "G + 2", package: "Basic Package", status: "COMPLETED" },
        { id: "CRN11011", image: "/assets/images/2f.jpeg", location: "62, 2nd Cross Rd, Basavanagara, Bengaluru", sqft: "3691 sq.ft.", type: "G + 3", package: "Classic", status: "COMPLETED" },
        { id: "CRN52922", image: "/assets/images/2.jpg", location: "Bannerghatta, Bengaluru", sqft: "3000 sq.ft.", type: "G + 1", package: "Classic", status: "COMPLETED" },
        { id: "CRN55944", image: "/assets/images/3f.jpeg", location: "Hennur Main Road, Bengaluru", sqft: "2446 sq.ft.", type: "G + 2", package: "Basic Package", status: "COMPLETED" },
        { id: "CRN11001", image: "/assets/images/3.jpg", location: "62, 2nd Cross Rd, Basavanagara, Bengaluru", sqft: "3691 sq.ft.", type: "G + 3", package: "Classic", status: "COMPLETED" },
        { id: "CRN52932", image: "/assets/images/4f.jpeg", location: "Bannerghatta, Bengaluru", sqft: "3000 sq.ft.", type: "G + 1", package: "Classic", status: "COMPLETED" },
        { id: "CRN55943", image: "/assets/images/4.jpg", location: "Hennur Main Road, Bengaluru", sqft: "2446 sq.ft.", type: "G + 2", package: "Basic Package", status: "COMPLETED" },
        { id: "CRN11002", image: "/assets/images/22.jpg", location: "62, 2nd Cross Rd, Basavanagara, Bengaluru", sqft: "3691 sq.ft.", type: "G + 3", package: "Classic", status: "COMPLETED" },
        { id: "CRN24691", image: "/assets/images/34f.jpeg", location: "Kengeri, Bengaluru", sqft: "1200 sq.ft.", type: "G + 1", package: "Classic", status: "ACTIVE" },
        { id: "CRN40115", image: "/assets/images/33f.jpeg", location: "Proposed residential building at bearing", sqft: "123 sq.ft.", type: "G + 1", package: "Classic", status: "ACTIVE" },
        { id: "CRN11003", image: "/assets/images/1f.jpg", location: "62, 2nd Cross Rd, Basavanagara, Bengaluru", sqft: "3691 sq.ft.", type: "G + 3", package: "Classic", status: "NEAR COMPLETION" },
        { id: "CRN40119", image: "/assets/images/33f.jpeg", location: "Proposed residential building at bearing", sqft: "123 sq.ft.", type: "G + 1", package: "Classic", status: "NEAR COMPLETION" },
    ];

    // Filter projects based on the active tab
    const filteredProjects = projects.filter(project => project.status === activeTab);

    // Get the number of projects to show for the active tab
    const currentProjectsCount = projectCounts[activeTab];

    // Slice the filtered projects to show the current number of projects
    const currentProjects = filteredProjects.slice(0, currentProjectsCount);

    // Handle "Show More" button click for the active tab
    const handleShowMore = () => {
        setProjectCounts(prevCounts => ({
            ...prevCounts,
            [activeTab]: prevCounts[activeTab] + 3, // Show 3 more projects per click
        }));
    };

    const [expandedIndex, setExpandedIndex] = useState(null);

    const toggleFAQ = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };


    return (
        <>
            <div>
                <section className="relative isolate py-16 sm:py-24 lg:py-32 font-serif">
                    {/* Background */}
                    <div
                        className="absolute inset-0 bg-cover bg-center"
                        style={{
                            backgroundImage: `linear-gradient(to bottom, 
            rgba(59, 48, 44, 0.9), 
            rgba(59, 48, 44, 0.9), 
            rgba(59, 48, 44, 0.7), 
            rgba(59, 48, 44, 0.8)),  
            url('/assets/images/wood1.jpg')`,
                            filter: "blur(0px)", // Adds the blur effect to the image.
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    ></div>

                    <div className="relative max-w-7xl mx-auto px-7 lg:px-8 flex items-center justify-center h-full">
                        {/* "Get in Touch" Section */}
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center text-left lg:text-left">
                            {/* Content Section */}

                            {/* Repeat Content Section */}
                            <div className=''>
                                <h1 className="text-6xl text-orange-200 mt-12 font-black"> PROJECTS</h1>

                                <p className="text-lg text-silver leading-relaxed mt-8">
                                    Our team of experts is dedicated to designing sustainable, high-quality prefab timber-framed houses tailored to your lifestyle.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>


                <div className="bg-orange-50 px-4 py-16 font-serif relative overflow-x-hidden">
                    {/* Header */}
                    <div className="mb-8 text-center">
                        <h1 className="text-5xl mb-2 font-bold text-gray-800">Our Projects</h1>
                        <p className="text-gray-600 text-lg">
                            Explore how our work has changed the life of our customers.
                        </p>
                    </div>

                    {/* Tabs */}
                    <div className="flex flex-wrap gap-4 mb-8 border-b justify-center sm:justify-start lg:justify-center">
                        {["COMPLETED", "NEAR COMPLETION", "ACTIVE"].map((tab) => (
                            <button
                                key={tab}
                                onClick={() => {
                                    setActiveTab(tab);
                                    setProjectCounts({ ...projectCounts, [tab]: 3 }); // Reset to 3 projects when switching tabs
                                }}
                                className={`pb-2 px-3 text-lg font-medium transition-colors ${activeTab === tab
                                    ? "border-b-2 border-orange-500 text-orange-500"
                                    : "text-gray-500 hover:text-gray-700"
                                    }`}
                            >
                                {tab}
                            </button>
                        ))}
                    </div>


                    {/* Projects Grid */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 p-8">
                        {currentProjects.map((project) => (
                            <div key={project.id} className=" bg-[#FAEBD7] overflow-hidden">
                                <div className="aspect-w-4 aspect-h-3">
                                    <img
                                        src={project.image}
                                        alt={`Image of ${project.id}`}
                                        className="w-full h-64 object-cover"
                                    />
                                </div>
                                <div className="p-6 text-center">
                                    <h3 className="text-xl font-semibold text-gray-800 mb-2">{project.id}</h3>
                                    <p className="text-gray-600 text-sm mb-2">{project.location}</p>
                                    <div className="flex flex-wrap justify-center gap-2 text-sm text-gray-500">
                                        <span>{project.sqft}</span>
                                        <span className='text-amber-700 text-md'>•</span>
                                        <span>{project.type}</span>
                                        <span className='text-amber-700 text-md'>•</span>
                                        <span>{project.package}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Show More Button */}
                    {currentProjectsCount < filteredProjects.length && (
                        <div className="flex justify-center mt-8">
                            <button
                                onClick={handleShowMore}
                                className="border border-orange-500 text-darkCoffee px-6 py-2 rounded-lg shadow hover:bg-orange-200 transition flex items-center justify-center gap-2"
                            >
                                Load More
                                <ChevronDown className="text-lg" />
                            </button>
                        </div>
                    )}
                </div>
                {/* contact */}




            </div >

            < div
                className="bg-orange-50 bg-cover bg-center p-4 md:p-8"

            >
                {/* Main Contact Section */}
                < div className="max-w-7xl mx-auto p-8 bg-orange-50 rounded-lg " >
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">

                        {/* Left Section */}
                        <div className=" flex flex-col justify-between">
                            <div>
                                <div className=" text-orange-400 font-medium text-sm mb-4">— CONTACT US —</div>
                                <h2 className="text-5xl font-serif mb-6 font-normal mb-4">Talk To Our Experts</h2>
                                <p className="text-gray-600 mb-6">
                                    Connect with our team of professionals to discuss your needs and find tailored solutions. Rest assured, we'll provide the expertise and guidance you deserve.
                                </p>
                            </div>
                            <div className="space-y-2">
                                <div className="bg-orange-50 p-2 rounded-lg flex items-center space-x-4">
                                    <Phone className="text-orange-500 w-6 h-6" />
                                    <div>
                                        <h3 className="font-semibold text-lg">Phone Number</h3>
                                        <p className="text-gray-600">+91 1234567890</p>
                                    </div>
                                </div>
                                <div className="bg-orange-50 p-2 rounded-lg flex items-center space-x-4">
                                    <Mail className="text-orange-500 w-6 h-6" />
                                    <div>
                                        <h3 className="font-semibold text-lg">Email Address</h3>
                                        <p className="text-gray-600">info@spacemaster.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Right Section - Form */}
                        <div>
                            <form className="space-y-8">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div className="relative">
                                        <input
                                            type="text"
                                            placeholder="Name"
                                            className="w-full p-3 border border-orange-200 rounded-lg pl-10"
                                        />
                                        <User className="w-5 h-5 text-gray-400 absolute left-3 top-3" />
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="tel"
                                            placeholder="Phone Number"
                                            className="w-full p-3 border border-orange-200 rounded-lg pl-10"
                                        />
                                        <Phone className="w-5 h-5 text-gray-400 absolute left-3 top-3" />
                                    </div>
                                </div>

                                <div className="relative">
                                    <input
                                        type="email"
                                        placeholder="Email Address"
                                        className="w-full p-3 border border-orange-200 rounded-lg pl-10"
                                    />
                                    <Mail className="w-5 h-5 text-gray-400 absolute left-3 top-3" />
                                </div>

                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder="Location of Your Plot"
                                        className="w-full p-3 border border-orange-200 rounded-lg pl-10"
                                    />
                                    <Briefcase className="w-5 h-5 text-gray-400 absolute left-3 top-3" />
                                </div>

                                <button
                                    type="submit"
                                    className="w-full bg-orange-500 text-white py-3 px-6 rounded-lg hover:bg-orange-600 transition-colors"
                                >
                                    Book Free Consultation
                                </button>
                            </form>
                        </div>
                    </div>
                </div >
            </div >















        </>

    );
}
