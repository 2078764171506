import React from 'react';
import Sidebar from '../pages/Sidebar';
import Dheader from './Dheader';
import Dfooter from './Dfooter';
import Project from '../pages/Project';

const Project1 = () => {
    return (


        <div className="dashboard-container  bg-white">
            <Sidebar /> {/* Render Sidebar here */}
            <div className="flex-1 p-6">
                {/* Header starts after the sidebar */}
                <div className="md:ml-64"> {/* Adjust the margin based on the sidebar's width */}
                    <Dheader />
                </div>
                <div className="md:ml-64">
                    <Project />
                </div>
                <div className='md:ml-64'>
                    <Dfooter />
                </div>
            </div>
        </div>
    );
};

export default Project1;
