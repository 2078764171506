import React, { useState } from 'react';
import { Phone, Mail, User, Briefcase, } from 'lucide-react';

import { Share2, ThumbsUp, Building2, } from 'lucide-react';

import { Dialog, } from '@headlessui/react';
import { FaHome, FaRupeeSign, } from 'react-icons/fa';
const FloorPlans = () => {

    const [visibleDesigns, setVisibleDesigns] = useState(6); // Default visible designs
    const [isModalOpen, setIsModalOpen] = useState(false); // Manage modal visibility



    const handleShowMore = () => {
        if (visibleDesigns < designs.length) {
            setVisibleDesigns(visibleDesigns + 6); // Show 6 more designs
        } else {
            setIsModalOpen(true); // Open the modal
        }
    };

    const closeModal = () => setIsModalOpen(false);
    const initialDesigns = [
        {
            image: "/assets/images/1f.jpg",
            likes: 30,
            type: "Residential",
            dimensions: "40x60 sq.ft",
            cost: "₹42,32,728",
            floors: "G+2"
        },
        {
            image: "/assets/images/2f.jpeg",
            likes: 27,
            type: "Residential",
            dimensions: "60x30 sq.ft",
            cost: "₹1,54,32,489",
            floors: "G+3"
        },
        {
            image: "/assets/images/3f.jpeg",
            likes: 24,
            type: "Residential",
            dimensions: "40x50 sq.ft",
            cost: "₹42,32,729",
            floors: "G+2"
        },
        {
            image: "/assets/images/4f.jpeg",
            likes: 22,
            type: "Residential",
            dimensions: "50x40 sq.ft",
            cost: "₹42,32,730",
            floors: "G+3"
        },
        {
            image: "/assets/images/21.jpg",
            likes: 24,
            type: "Residential",
            dimensions: "40x50 sq.ft",
            cost: "₹42,32,729",
            floors: "G+2"
        },
        {
            image: "/assets/images/22.jpg",
            likes: 22,
            type: "Residential",
            dimensions: "50x40 sq.ft",
            cost: "₹42,32,730",
            floors: "G+3"
        },
        {
            image: "/assets/images/21.jpg",
            likes: 24,
            type: "Residential",
            dimensions: "40x50 sq.ft",
            cost: "₹42,32,729",
            floors: "G+2"
        },
        {
            image: "/assets/images/22.jpg",
            likes: 22,
            type: "Residential",
            dimensions: "50x40 sq.ft",
            cost: "₹42,32,730",
            floors: "G+3"
        }
    ];



    // State for handling likes
    const [designs, setDesigns] = useState(initialDesigns);

    // Handle like button click
    const handleLike = (id) => {
        setDesigns(prevDesigns =>
            prevDesigns.map(design =>
                design.id === id
                    ? { ...design, likes: design.likes + 1 }
                    : design
            )
        );
    };

    // Handle share button click
    const handleShare = (id) => {
        const design = designs.find(d => d.id === id);

        if (navigator.share) {
            navigator.share({
                title: `Floor Plan: ${design.dimensions}`,
                text: `Check out this floor plan: ${design.dimensions}`,
                url: window.location.href,
            })
                .then(() => console.log('Shared successfully'))
                .catch((error) => console.error('Error sharing', error));
        } else {
            alert(`Share this design: ${design.dimensions}`);
        }
    };

    return (
        <>
            <div>
                <section className="relative isolate py-16 sm:py-24 lg:py-32 font-serif">
                    {/* Background */}
                    <div
                        className="absolute inset-0 bg-cover bg-center"
                        style={{
                            backgroundImage: `linear-gradient(to bottom, 
            rgba(59, 48, 44, 0.9), 
            rgba(59, 48, 44, 0.8), 
            rgba(59, 48, 44, 0.7), 
            rgba(59, 48, 44, 0.8)),  
            url('/assets/images/villa.jpg')`,
                            filter: "blur(0px)", // Adds the blur effect to the image.
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    ></div>

                    <div className="relative max-w-7xl mx-auto px-7 lg:px-8 flex items-center justify-center h-full">
                        {/* "Get in Touch" Section */}
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center text-left lg:text-left">
                            {/* Content Section */}

                            {/* Repeat Content Section */}
                            <div className=''>
                                <h1 className="text-6xl text-orange-200 mt-12 font-black"> Designs &  Floor Plans</h1>

                                <p className="text-lg text-white leading-relaxed mt-8">
                                    Explore expertly curated floor plans by SpaceMaster Constructions, designed to match your vision and lifestyle.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>





                <div>


                    <div className="flex min-h-screen bg-orange-50 font-serif flex-col-reverse lg:flex-row p-8">
                        {/* Sidebar (Floor component) */}

                        {/* Main Content */}
                        <div className="flex-1 p-6">
                            <div className="mb-6">
                                <div className="text-amber-700 font-medium mb-4">— FLOOR PLANS —</div>
                                <h1 className="text-5xl font-serif mb-2 text-darkCoffee">
                                    Total Floor Plans - <span className="text-amber-700">500 Designs</span>
                                    <p className="text-lg text-gray-700  leading-relaxed max-w-lg text-left mt-4">
                                        Discover a wide range of curated floor plans tailored to meet your needs.
                                        Browse through modern and traditional designs for your dream home.
                                    </p>
                                </h1>
                            </div>

                            {/* Grid of Designs */}
                            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                {designs.slice(0, visibleDesigns).map((design, index) => (
                                    <div key={index} className="group bg-orange-100  overflow-hidden relative">
                                        <div className="relative">
                                            <img
                                                src={design.image}
                                                alt="Floor plan"
                                                className="w-full h-48 object-cover"
                                            />
                                            <button
                                                onClick={() => handleShare(design.id)}
                                                className="absolute top-4 right-4 p-2 bg-amber-600 rounded-full shadow-md"
                                            >
                                                <Share2 className="w-4 h-4 text-white" />
                                            </button>
                                        </div>

                                        <div className="p-4">
                                            <div className="flex items-center gap-2 mb-3">
                                                <ThumbsUp
                                                    onClick={() => handleLike(design.id)}
                                                    className="w-4 h-4 text-orange-400 cursor-pointer"
                                                />
                                                <span className="text-gray-600">{design.likes}</span>
                                                <span className="text-orange-500 text-right">{design.type}</span>
                                            </div>

                                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                                                <div className="flex items-center gap-2">
                                                    <Building2 className="w-5 h-5 text-orange-300" />
                                                    <span className="text-sm text-gray-600"> {design.dimensions}</span>
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    <FaRupeeSign className="w-5 h-5 text-orange-300" />
                                                    <span className="text-sm text-gray-600"> {design.cost}</span>
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    <FaHome className="w-5 h-5 text-orange-300" />
                                                    <span className="text-sm text-gray-600"> {design.floors}</span>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                ))}
                            </div>

                            {/* Show More Button */}
                            <div className="text-center mt-6">
                                <button
                                    onClick={handleShowMore}
                                    className="border border-amber-600 text-darkCoffee py-2 px-4 rounded-full hover:bg-amber-800"
                                >
                                    Show More
                                </button>
                            </div>
                        </div>
                    </div>

                    {isModalOpen && (
                        <Dialog
                            open={isModalOpen}
                            onClose={closeModal}
                            className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50 font-serif"
                        >
                            <div className="bg-orange-50  p-8 w-96 relative">
                                <button
                                    onClick={closeModal}
                                    className="absolute top-3 right-3 text-gray-500 hover:text-gray-800"
                                >
                                    ✕
                                </button>
                                <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
                                    <span className="text-darkCoffee-600">Get A Call Back</span>
                                </h2>
                                <form>
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            <span className="flex items-center">
                                                <i className="pi pi-user text-amber-600 mr-2"></i>Name
                                            </span>
                                        </label>
                                        <input
                                            type="text"
                                            className="w-full border rounded-full px-4 py-2 focus:outline-none focus:ring-2 focus:ring-amber-300"
                                            placeholder="Enter your name"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            <span className="flex items-center">
                                                <i className="pi pi-envelope text-amber-600 mr-2"></i>Email
                                            </span>
                                        </label>
                                        <input
                                            type="email"
                                            className="w-full border rounded-full px-4 py-2 focus:outline-none focus:ring-2 focus:ring-amber-300"
                                            placeholder="Enter your email"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            <span className="flex items-center">
                                                <i className="pi pi-phone text-amber-600 mr-2"></i>Phone Number
                                            </span>
                                        </label>
                                        <input
                                            type="tel"
                                            className="w-full border rounded-full px-4 py-2 focus:outline-none focus:ring-2 focus:ring-amber-300"
                                            placeholder="Enter your phone number"
                                        />
                                    </div>
                                    <div className="mb-6">
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            <span className="flex items-center">
                                                <i className="pi pi-comment text-amber-600 mr-2"></i>Message
                                            </span>
                                        </label>
                                        <textarea
                                            className="w-full border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-amber-300"
                                            placeholder="Write your message"
                                            rows="4"
                                        ></textarea>
                                    </div>
                                    <div className="text-center">
                                        <button
                                            type="submit"
                                            className="bg-amber-600 text-white py-2 px-6 rounded-full hover:bg-amber-700 transition-all"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Dialog>
                    )}


                </div>

                {/* contact */}
                <div
                    className="bg-orange-50 bg-cover bg-center p-4 md:p-8"

                >
                    {/* Main Contact Section */}
                    <div className="max-w-7xl mx-auto p-8 bg-orange-50 rounded-lg ">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">

                            {/* Left Section */}
                            <div className="flex flex-col justify-between">
                                <div>
                                    <div className="text-amber-700 font-medium text-sm mb-4">— CONTACT US —</div>
                                    <h2 className="text-5xl font-serif mb-6 font-normal mb-4">Talk To Our Experts</h2>
                                    <p className="text-gray-600 mb-6">
                                        Connect with our team of professionals to discuss your needs and find tailored solutions. Rest assured, we'll provide the expertise and guidance you deserve.
                                    </p>
                                </div>
                                <div className="space-y-2">
                                    <div className="bg-orange-50 p-2 rounded-lg flex items-center space-x-4">
                                        <Phone className="text-orange-500 w-6 h-6" />
                                        <div>
                                            <h3 className="font-semibold text-lg">Phone Number</h3>
                                            <p className="text-gray-600">+91 1234567890</p>
                                        </div>
                                    </div>
                                    <div className="bg-orange-50 p-2 rounded-lg flex items-center space-x-4">
                                        <Mail className="text-orange-500 w-6 h-6" />
                                        <div>
                                            <h3 className="font-semibold text-lg">Email Address</h3>
                                            <p className="text-gray-600">info@spacemaster.com</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Right Section - Form */}
                            <div>
                                <form className="space-y-8">
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        <div className="relative">
                                            <input
                                                type="text"
                                                placeholder="Name"
                                                className="w-full p-3 border border-orange-200 rounded-lg pl-10"
                                            />
                                            <User className="w-5 h-5 text-gray-400 absolute left-3 top-3" />
                                        </div>
                                        <div className="relative">
                                            <input
                                                type="tel"
                                                placeholder="Phone Number"
                                                className="w-full p-3 border border-orange-200 rounded-lg pl-10"
                                            />
                                            <Phone className="w-5 h-5 text-gray-400 absolute left-3 top-3" />
                                        </div>
                                    </div>

                                    <div className="relative">
                                        <input
                                            type="email"
                                            placeholder="Email Address"
                                            className="w-full p-3 border border-orange-200 rounded-lg pl-10"
                                        />
                                        <Mail className="w-5 h-5 text-gray-400 absolute left-3 top-3" />
                                    </div>

                                    <div className="relative">
                                        <input
                                            type="text"
                                            placeholder="Location of Your Plot"
                                            className="w-full p-3 border border-orange-200 rounded-lg pl-10"
                                        />
                                        <Briefcase className="w-5 h-5 text-gray-400 absolute left-3 top-3" />
                                    </div>

                                    <button
                                        type="submit"
                                        className="w-full bg-orange-500 text-white py-3 px-6 rounded-lg hover:bg-orange-600 transition-colors"
                                    >
                                        Book Free Consultation
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>

    );
};

export default FloorPlans;
