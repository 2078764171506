import React from 'react';
import { Popover, Transition } from '@headlessui/react';
import { BellIcon, UserCircleIcon } from 'lucide-react';
// You can use any icons you prefer.

const Dheader = () => {

    return (
        <header className="bg-white sticky top-0 z-50  font-serif">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                    {/* Left side: Logo and welcome text */}
                    <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                            {/* Logo or brand name */}
                            <h1 className="text-3xl text-gray-800 block lg:hidden hover:text-orange-400">
                                SpaceMaster
                            </h1>
                            <h1 className="text-3xl  text-gray-800 hidden lg:block">
                                Welcome Back, John
                            </h1>
                        </div>
                    </div>

                    {/* Right side: User options */}
                    <div className="flex items-center space-x-6">
                        {/* Notifications Icon */}
                        <Popover className="relative">
                            <Popover.Button className="p-2 rounded-full hover:bg-gray-100 transition">
                                <BellIcon className="h-6 w-6 text-darkCoffee hover:text-orange-500" />
                            </Popover.Button>
                            <Transition
                                as="div"
                                enter="transition duration-200 ease-out"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="transition duration-100 ease-in"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Popover.Panel className="absolute right-0 mt-2 w-64 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                                    <div className="p-4">
                                        <p className="text-gray-700">You have no new notifications.</p>
                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </Popover>

                        {/* User Profile */}
                        {/* User Profile */}
                        <Popover className="relative">
                            <Popover.Button className="p-2 rounded-full hover:bg-gray-100 transition flex items-center space-x-3 cursor-pointer">
                                {/* Profile Image */}
                                <img
                                    src="https://readymadeui.com/team-2.webp"
                                    alt="User Profile"
                                    className="w-10 h-10 rounded-full border-2 border-white"
                                />
                                {/* User Name */}
                                <span className="text-gray-900 font-medium hidden sm:block">
                                    John Doe
                                </span>
                            </Popover.Button>

                            <Transition
                                as="div"
                                enter="transition duration-200 ease-out"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="transition duration-100 ease-in"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Popover.Panel className="absolute right-0 mt-2 w-48 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                                    <div className="p-4">
                                        {/* View Profile Link */}
                                        <a
                                            href="/profile"
                                            className="block text-blue-500 hover:text-blue-700 text-center font-medium"
                                        >
                                            View Profile
                                        </a>
                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </Popover>

                    </div>
                </div>
            </div>
        </header>


    );
};

export default Dheader;
