import { useState, useEffect } from 'react';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { FaPhoneAlt } from 'react-icons/fa';
import { CheckIcon } from '@heroicons/react/20/solid';

export default function ContactFormModal() {
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        country: 'US',
        phone: '',
        email: '',
        location: '',
        agreed: false,
    });
    const [errors, setErrors] = useState({});

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Add form submission logic here
            console.log(formData); // for now, just log formData
        }
    };

    // Show the modal after 2 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            setOpen(true);
        }, 20000);

        return () => clearTimeout(timer);
    }, []);

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Simple form validation
    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.phone || !/^\d{10}$/.test(formData.phone)) {
            newErrors.phone = 'Valid phone number is required';
        }
        if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Valid email is required';
        }
        if (!formData.location) newErrors.location = 'Location is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    return (
        <>


            {/* Modal Dialog */}
            <Dialog open={open} onClose={() => setOpen(false)} className="relative z-50 font-serif">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-opacity-50 bg-black transition-opacity"
                />
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                        >
                            {/* Modal Header */}
                            <div className="bg-orange-50 px-4 py-5 sm:p-6 sm:pb-4 text-center">
                                <DialogTitle as="h3" className="text-2xl font-semibold text-gray-900">
                                    Talk to Our Experts
                                </DialogTitle>
                            </div>

                            {/* Close Button */}
                            <button
                                type="button"
                                onClick={() => setOpen(false)}
                                className="absolute top-2 right-2 text-gray-500 hover:text-gray-900 focus:outline-none"
                            >
                                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>

                            {/* Contact Form */}
                            <div className="bg-orange-50 px-4 py-6 sm:px-6">
                                <form className="space-y-6" onSubmit={handleSubmit}>
                                    {/* Name */}
                                    <div className="flex flex-col space-y-2">
                                        {/* <label htmlFor="name" className="text-gray-700">Full Name</label> */}
                                        <div className="border-b border-gray-300 py-2">
                                            <input
                                                type="text"
                                                name="name"
                                                className="bg-transparent text-gray-700 w-full focus:outline-none"
                                                placeholder="Name*"
                                                value={formData.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    {/* Mobile */}
                                    <div className="flex flex-col space-y-2">
                                        {/* <label htmlFor="phone" className="text-gray-700">Phone Number</label> */}
                                        <div className="flex items-center border-b border-gray-300 py-2">
                                            <FaPhoneAlt className="text-gray-600 mr-2" />
                                            <input
                                                type="tel"
                                                name="phone"
                                                className="bg-transparent text-gray-700 w-full focus:outline-none"
                                                placeholder="Mobile Number*"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    {/* Email */}
                                    <div className="flex flex-col space-y-2">
                                        {/* <label htmlFor="email" className="text-gray-700">Email Address</label> */}
                                        <div className="border-b border-gray-300 py-2">
                                            <input
                                                type="email"
                                                name="email"
                                                className="bg-transparent text-gray-700 w-full focus:outline-none"
                                                placeholder="Email"
                                                value={formData.email}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    {/* Location */}
                                    <div className="flex flex-col space-y-2">
                                        {/* <label htmlFor="location" className="text-gray-700">Location of Your Plot</label> */}
                                        <div className="border-b border-gray-300 py-2">
                                            <input
                                                type="text"
                                                name="location"
                                                className="bg-transparent text-gray-700 w-full focus:outline-none"
                                                placeholder="Location of plot*"
                                                value={formData.location}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    {/* Consent Checkbox */}


                                    {/* Submit Button */}
                                    <button
                                        type="submit"
                                        className="bg-orange-300 hover:bg-orange-400 text-darkCoffee px-6 py-3 rounded-md w-full "
                                    >
                                        Book Free Consultation
                                    </button>

                                    {/* Privacy Policy Disclaimer */}
                                    <p className="text-sm text-gray-500 mt-4">
                                        *By submitting this form, I confirm that I have read and agreed to accept the privacy policy.
                                    </p>
                                </form>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    );
}
