import React, { useState } from 'react';
import { FaArrowLeft, FaEnvelope, FaEye, FaLock } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import { FaEyeSlash } from "react-icons/fa";
export default function Login() {

    const [showPassword, setShowPassword] = useState(false);


    const navigate = useNavigate();

    return (
        <>
            <section className="relative  min-h-screen flex items-center justify-center font-serif">
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{
                        backgroundImage: `linear-gradient(to bottom, 
              rgba(59, 48, 44, 0.9), 
              rgba(59, 48, 44, 0.9), 
              rgba(59, 48, 44, 0.9), 
              rgba(59, 48, 44, 0.8)),  
              url('/assets/images/mining.jpg')`,
                        filter: "blur(0px)", // Adds the blur effect to the image
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                ></div>


                {/* Login Form Section */}
                <div className="flex min-h-[50vh] items-center justify-center px-4 font-serif">
                    <div className="relative max-w-3xl w-full p-6 bg-orange-50 bg-opacity-15 shadow-lg">
                        {/* Close Button */}
                        <button
                            onClick={() => navigate('/')}
                            className="absolute top-2 right-2 text-white hover:text-gray-200 focus:outline-none"
                        >
                            <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                ></path>
                            </svg>
                        </button>

                        {/* Header */}
                        <div className="text-center mt-4">
                            <h2 className="text-3xl text-orange-200">LOGIN</h2>
                            <p className="mt-2 text-gray-200 text-sm">
                                Don't have an account yet?{" "}
                                <a
                                    href="/signup"
                                    className="text-orange-300 hover:underline text-sm"
                                >
                                    Create now
                                </a>
                            </p>
                        </div>

                        {/* Form */}
                        <form className="space-y-6 mt-6">
                            {/* Email Field */}
                            <div>
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium text-gray-50"
                                >
                                    Email
                                </label>
                                <div className="relative">
                                    <FaEnvelope className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                                    <input
                                        id="email"
                                        type="email"
                                        placeholder="Enter email"
                                        className="p-inputtext w-full pl-10 pr-4 py-2 text-gray-900 border border-gray-300  rounded-md bg-orange-50"
                                        required
                                    />
                                </div>
                            </div>

                            {/* Password Field */}
                            <div>
                                <label
                                    htmlFor="password"
                                    className="block text-sm font-medium text-gray-50"
                                >
                                    Password
                                </label>
                                <div className="relative">
                                    <FaLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                                    <input
                                        id="password"
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Enter password"
                                        className="p-inputtext w-full pl-10 pr-10 py-2 text-gray-900  bg-orange-50 rounded-md"
                                        required
                                    />
                                    <button
                                        type="button"
                                        onClick={() => setShowPassword(!showPassword)}
                                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                                    >
                                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                                    </button>
                                </div>
                                <a
                                    href="/forgot-password"
                                    className="text-sm text-orange-300 hover:underline mt-2 block text-left"
                                >
                                    Forgot Password?
                                </a>
                            </div>

                            {/* Sign In Button */}
                            <a
                                href='/spacemaster-dashboard'
                                type="submit"
                                className="w-full py-2 bg-amber-700 text-white rounded-md hover:bg-amber-800 focus:ring-2 focus:ring-amber-500 shadow-md text-center"
                            >
                                Sign in
                            </a>

                            {/* Sign in with Google */}
                            <div className="mt-6">
                                <button
                                    type="button"
                                    className="transition-colors focus:ring-2 focus:ring-gray-200 p-0.5 bg-white hover:bg-gray-100 text-gray-900 border border-gray-200 rounded-md w-full"
                                >
                                    <span className="flex items-center justify-center gap-1 font-medium py-1">
                                        <svg
                                            className="w-5 h-5"
                                            viewBox="0 0 48 48"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill="#FFC107"
                                                d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12 c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24 c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                                            ></path>
                                            <path
                                                fill="#FF3D00"
                                                d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657 C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                                            ></path>
                                            <path
                                                fill="#4CAF50"
                                                d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36 c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                                            ></path>
                                            <path
                                                fill="#1976D2"
                                                d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571 c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                                            ></path>
                                        </svg>
                                        Sign in with Google
                                    </span>
                                </button>
                            </div>
                        </form>

                        {/* Back to Home */}
                        <a
                            href="/"
                            className="flex items-center gap-2 text-orange-200 hover:text-orange-300 mt-4"
                        >
                            <span className="p-2 border border-orange-200 rounded-full">
                                <FaArrowLeft className="text-orange-200" />
                            </span>
                            Back to Home
                        </a>
                    </div>
                </div>



            </section>
        </>
    );
}
