// src/Header.js
import React, { useEffect, useState } from 'react';
import { FaPhoneAlt, FaBars, FaTimes, FaFacebook, FaTwitter, FaLinkedin, FaInstagram, FaBuilding, FaHome, FaClipboard, FaWhatsapp, FaYoutube, FaMoneyBillAlt, FaPaperPlane, FaBox } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(prev => !prev);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <header
            className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${isScrolled ? 'bg-charcoal shadow-lg' : 'bg-transparent'}`}
        >
            <div className="container mx-auto flex justify-between items-center py-4 px-6">
                <a href='/'
                    className="text-3xl  text-white base font-serif hover:text-orange-200 ">
                    SpaceMaster
                </a>
                <div className="md:hidden">
                    <button onClick={toggleMenu} className="text-white focus:outline-none ">
                        {isMenuOpen ? <FaTimes size={20} /> : <FaBars size={20} />}
                    </button>
                </div>
                <nav className="hidden md:flex gap-8 font-sans text-white text-sm lg:text-md font-serif">
                    <NavLink
                        to="/"
                        className={({ isActive }) =>
                            isActive
                                ? 'text-orange-200  border-b-2 border-orange-200 transition duration-300'
                                : 'hover:text-orange-200 transition duration-300'
                        }
                    >
                        Home
                    </NavLink>
                    <NavLink
                        to="/about-us"
                        className={({ isActive }) =>
                            isActive
                                ? 'text-orange-200  border-b-2 border-orange-200 transition duration-300'
                                : 'hover:text-orange-200 transition duration-300'
                        }
                    >
                        About Us
                    </NavLink>
                    <NavLink
                        to="/our-project"
                        className={({ isActive }) =>
                            isActive
                                ? 'text-orange-200  border-b-2 border-orange-200 transition duration-300'
                                : 'hover:text-orange-200 transition duration-300'
                        }
                    >
                        Our Projects
                    </NavLink>
                    <NavLink
                        to="/floor-plans"
                        className={({ isActive }) =>
                            isActive
                                ? 'text-orange-200  border-b-2 border-orange-200 transition duration-300'
                                : 'hover:text-orange-200 transition duration-300'
                        }
                    >
                        Floor Plans
                    </NavLink>
                    <NavLink
                        to="/cost-estimator"
                        className={({ isActive }) =>
                            isActive
                                ? 'text-orange-200  border-b-2 border-orange-200 transition duration-300'
                                : 'hover:text-orange-200 transition duration-300'
                        }
                    >
                        Cost Estimator
                    </NavLink>
                    <NavLink
                        to="/package"
                        className={({ isActive }) =>
                            isActive
                                ? 'text-orange-200  border-b-2 border-orange-200 transition duration-300'
                                : 'hover:text-orange-200 transition duration-300'
                        }
                    >
                        Packages
                    </NavLink>
                    <NavLink
                        to="/contact-us"
                        className={({ isActive }) =>
                            isActive
                                ? 'text-orange-200  border-b-2 border-orange-200 transition duration-300'
                                : 'hover:text-orange-200 transition duration-300'
                        }
                    >
                        Contact Us
                    </NavLink>
                </nav>

                <div className="hidden md:flex gap-6">
                    {/* Login Link */}
                    <a href="/login-page" className="font-serif text-orange-300 hover:text-orange-400 transition duration-300">
                        Login
                    </a>

                    <a href="tel:+1234567890" className="font-serif flex items-center gap-2 text-white transition duration-300">
                        <FaPhoneAlt /> +123 456 7890
                    </a>

                </div>
            </div>
            {/* Mobile Menu */}

            <div
                className={`md:hidden font-serif ${isMenuOpen ? 'fixed inset-0 bg-darkCoffee p-6 flex flex-col items-start justify-start overflow-y-auto' : 'hidden'}`}
            >
                <button onClick={closeMenu} className="absolute top-4 right-4 text-white">
                    <FaTimes size={20} />
                </button>

                {/* Company Name */}
                <h1 className="text-3xl font-display text-silver bg-clip-text text-transparent custom-gradient font-serif mb-6">
                    SpaceMaster
                </h1>

                <nav className="flex flex-col gap-8 text-orange-200 text-xl font-serif mt-6 font-medium">
                    {/* Menu Links */}
                    <a href="/" className="flex items-center gap-3 hover:text-amber-600 transition duration-300" onClick={closeMenu}>
                        <FaHome /> Home
                    </a>
                    <a href="/about-us" className="flex items-center gap-3 hover:text-amber-600 transition duration-300" onClick={closeMenu}>
                        <FaPaperPlane /> About Us
                    </a>
                    <a href="/our-project" className="flex items-center gap-3 hover:text-amber-600 transition duration-300" onClick={closeMenu}>
                        <FaClipboard /> Our Projects
                    </a>
                    <a href="/floor-plans" className="flex items-center gap-3 hover:text-amber-600 transition duration-300" onClick={closeMenu}>
                        <FaBuilding /> Floor Plans
                    </a>
                    <a href="/cost-estimator" className="flex items-center gap-3 hover:text-amber-600 transition duration-300" onClick={closeMenu}>
                        <FaMoneyBillAlt /> Cost Estimation
                    </a>
                    <a href="/package" className="flex items-center gap-3 hover:text-amber-600 transition duration-300" onClick={closeMenu}>
                        <FaBox /> Packages
                    </a>
                    <a href="/contact-us" className="flex items-center gap-3 hover:text-amber-600 transition duration-300" onClick={closeMenu}>
                        <FaPhoneAlt /> Contact Us
                    </a>

                    <nav className="flex justify-start items-center gap-4">
                        <a
                            className="border border-white text-orange-300 py-2 px-4 rounded hover:border-amber-700 hover:text-amber-600 transition duration-300"
                            href='/login-page'
                        >
                            Login
                        </a>
                    </nav>

                    {/* Social Media & Subscribe Section */}
                    <div className="md:hidden p-2 text-white mt-6">
                        {/* Heading Section */}
                        <h2 className="text-2xl text-orange-200 mb-4">Subscribe & Follow</h2>

                        {/* Description Section */}
                        <p className="text-sm mb-6">
                            At Spacemaster Construction, we create sustainable, high-quality properties. Our team brings your vision to life with precision and innovation. Stay connected for updates on trends and new projects.
                        </p>

                        {/* Social Media Icons */}
                        <div className="flex gap-4 mb-6">
                            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-orange-200 hover:text-white transition duration-300">
                                <FaFacebook size={26} />
                            </a>
                            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-orange-200 hover:text-white transition duration-300">
                                <FaTwitter size={26} />
                            </a>
                            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-orange-200 hover:text-white transition duration-300">
                                <FaLinkedin size={26} />
                            </a>
                            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-orange-200 hover:text-white transition duration-300">
                                <FaInstagram size={26} />
                            </a>
                            <a href="https://whatsapp.com" target="_blank" rel="noopener noreferrer" className="text-orange-200 hover:text-white transition duration-300">
                                <FaWhatsapp size={26} />
                            </a>
                            <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" className="text-orange-200 hover:text-white transition duration-300">
                                <FaYoutube size={26} />
                            </a>



                        </div>

                        {/* Subscribe Button */}
                        <button
                            onClick={() => alert('Subscribed!')}
                            className="bg-orange-300 text-darkCoffee font-bold py-2 px-2  text-xl w-full mt-4 hover:bg-orange-300 transition duration-300"
                        >
                            Subscribe Now
                        </button>
                    </div>
                </nav>
            </div>


        </header>
    );
};

export default Header;
