import React from 'react';

export default function Notfound() {
    return (
        <>
            <section className="relative bg-white min-h-screen flex items-center justify-center font-serif">
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{
                        backgroundImage: `linear-gradient(to bottom, 
              rgba(59, 48, 44, 0.9), 
              rgba(59, 48, 44, 0.9), 
              rgba(59, 48, 44, 0.7), 
              rgba(59, 48, 44, 0.8)),  
              url('/assets/images/villa.jpg')`,
                        filter: "blur(0px)", // Adds the blur effect to the image
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                ></div>
                <div className="relative py-16 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 text-center">
                    <div className="mx-auto max-w-screen-sm">
                        {/* 404 Section with transparent background */}
                        <h1 className="mb-4 text-8xl tracking-tight font-extrabold lg:text-9xl text-orange-200 ">
                            404
                        </h1>
                        <p className="mb-4 text-4xl tracking-tight font-bold text-orange-200 font-semibold md:text-4xl">
                            Page Not Found
                        </p>
                        <p className="mb-4 text-lg font-light text-silver">
                            Sorry, we can't find that page. You'll find lots to explore on the home page.
                        </p>
                        <a
                            href="/"
                            className=" items-center justify-center text-silver bg-primary-600 font-semibold text-2xl hover:bg-primary-800 hover:text-orange-300 px-6 py-3 rounded-lg transition duration-300 ease-in-out focus:outline-none focus:ring-4 focus:ring-primary-300"
                        >
                            Back to Homepage
                        </a>

                    </div>
                </div>
            </section>
        </>
    );
}
