import React from 'react';
import {


    FaUserFriends,
    FaUser,
    FaBox,
    FaBuilding,
    FaUserCog,
} from 'react-icons/fa';


export const links = [
    {
        id: 1,
        url: '/spacemaster-dashboard',
        text: 'Dashboard',
        icon: <FaUserCog className="w-5 h-5" />,
    },
    {
        id: 2,
        url: '/spacemaster-customer',
        text: 'Constumers',
        icon: <FaUser className="w-5 h-5" />,
    },
    {
        id: 3,
        url: '/spacemaster-enquires',
        text: 'Enquiries',
        icon: <FaUserFriends className="w-5 h-5" />,
    },
    {
        id: 4,
        url: '/spacemaster-project',
        text: 'Projects',
        icon: <FaBuilding className="w-5 h-5" />,
    },
    {
        id: 5,
        url: '/spacemaster-packages',
        text: 'Packages',
        icon: <FaBox className="w-5 h-5" />,
    },

];
