import React, { useEffect, useState } from 'react';

const Dfooter = () => {
    const [currentYear, setCurrentYear] = useState('');

    useEffect(() => {
        // Set the current year when the component mounts
        setCurrentYear(new Date().getFullYear());
    }, []);

    return (
        <footer className="text-white py-4 mt-8">
            <div className="max-w-7xl mx-auto flex justify-between items-center">
                {/* Company Info */}
                <div className="text-sm text-silver">
                    &copy; {currentYear} SpaceMaster Constructions. All rights reserved.
                </div>

                {/* Help/Support Links */}
                <div className="text-sm space-x-4">
                    <a href="/help" className=" text-silver hover:text-orange-400">
                        Help
                    </a>
                    <a href="/support" className=" text-silver hover:text-orange-400">
                        Support
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Dfooter;
