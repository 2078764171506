import React, { useEffect, useState } from 'react';
import {
    FaArrowUp,
    FaEnvelope,
    FaFacebook,
    FaInstagram,
    FaLinkedin,
    FaMapMarkerAlt,
    FaPhone,
    FaTwitter,
    FaWhatsapp,
    FaYoutube,
} from 'react-icons/fa';

const Footer = () => {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const heroSectionHeight = document.querySelector("#hero-section")?.offsetHeight || 0;
            setShowButton(window.scrollY > heroSectionHeight);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    const openWhatsApp = () => {
        const whatsappNumber = "1234567890"; // Replace with your number
        window.open(`https://wa.me/${whatsappNumber}`, "_blank");
    };


    return (
        <footer className="relative bg-charcoal text-silver py-10 font-serif">
            <div
                className="absolute inset-0 bg-cover bg-center"
                style={{
                    backgroundImage:
                        "linear-gradient(rgba(25, 23, 22, 0.9), rgba(25, 23, 22, 0.9)), url('/assets/images/wood.jpg')",
                }}
            ></div>

            <div className="relative mx-auto w-full max-w-screen-xl px-4 sm:px-6 lg:px-8 z-10">
                <div className="md:flex md:justify-between md:flex-wrap">
                    {/* Branding Section */}
                    <div className="mb-6 md:mb-0 md:w-1/3">
                        <h2 className="text-4xl font-normal text-white">
                            SpaceMaster Construction
                        </h2>
                        <p className="text-silver mb-6 text-md mt-4">
                            At SpaceMaster Construction, we create <br />high-quality homes that reflect your style <br /> and needs with precision and care.
                        </p>

                        <div className="mt-6 flex flex-wrap justify-start space-x-4">
                            {/* Social Media Icons */}
                            <a
                                href="https://facebook.com"
                                className="text-orange-200 hover:text-blue-700"
                                aria-label="Facebook"
                            >
                                <FaFacebook size={22} />
                            </a>
                            <a
                                href="https://instagram.com"
                                className="text-orange-200 hover:text-pink-600"
                                aria-label="Instagram"
                            >
                                <FaInstagram size={22} />
                            </a>
                            <a
                                href="https://linkedin.com"
                                className="text-orange-200 hover:text-blue-600"
                                aria-label="LinkedIn"
                            >
                                <FaLinkedin size={22} />
                            </a>
                            <a
                                href="https://twitter.com"
                                className="text-orange-200 hover:text-blue-400"
                                aria-label="Twitter"
                            >
                                <FaTwitter size={22} />
                            </a>
                            <a
                                href="https://youtube.com"
                                className="text-orange-200 hover:text-red-500"
                                aria-label="YouTube"
                            >
                                <FaYoutube size={22} />
                            </a>
                            <a
                                href="https://whatspp.com"
                                className="text-orange-200 hover:text-green-600"
                                aria-label="Whatsapp"
                            >
                                <FaWhatsapp size={22} />
                            </a>
                        </div>
                    </div>

                    {/* Links Section */}
                    <div className="grid grid-cols-2 gap-6 sm:grid-cols-3 w-full md:w-2/3">
                        <div>
                            <h2 className="mb-6 text-sm font-semibold text-orange-200 uppercase">
                                Resources
                            </h2>
                            <ul className="text-silver font-medium">
                                <li className="mb-3">
                                    <a href="/" className="hover:text-gunmetal">Home</a>
                                </li>
                                <li className="mb-3">
                                    <a href="/about-us" className="hover:text-gunmetal">About Us</a>
                                </li>
                                <li className="mb-3">
                                    <a href="/our-project" className="hover:text-gunmetal">
                                        Our Projects
                                    </a>
                                </li>
                                <li className="mb-3">
                                    <a href="/floor-plans" className="hover:text-gunmetal">Floor Plans</a>
                                </li>
                                <li className="mb-3">
                                    <a href="/cost-Estimator" className="hover:text-gunmetal">Cost Estimator</a>
                                </li>



                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-sm font-semibold text-orange-200 uppercase">
                                Legal
                            </h2>
                            <ul className="text-silver font-medium">
                                <li className="mb-3">
                                    <a href="#" className="hover:text-gunmetal">Privacy Policy</a>
                                </li>
                                <li className="mb-3">
                                    <a href="#" className="hover:text-gunmetal">Referral Policy</a>
                                </li>
                                <li className="mb-3">
                                    <a href="#" className="hover:text-gunmetal">FAQs</a>
                                </li>
                                <li className="mb-3">
                                    <a href="#" className="hover:text-gunmetal">
                                        Terms & Conditions
                                    </a>
                                </li>
                                <li className="mb-3">
                                    <a href="#" className="hover:text-gunmetal">Help Center</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-sm font-semibold text-orange-200 uppercase">
                                Get in Touch
                            </h2>
                            <ul className="text-silver font-medium">
                                <li className="mb-4 flex items-center">
                                    <FaMapMarkerAlt className="mr-2" />
                                    <span>Bangalore, Karnataka, India</span>
                                </li>
                                <li className="mb-4 flex items-center">
                                    <FaEnvelope className="mr-2" />
                                    <a
                                        href="mailto:info@example.com"
                                        className="hover:text-gunmetal"
                                    >
                                        info@example.com
                                    </a>
                                </li>
                                <li className="flex items-center">
                                    <FaPhone className="mr-2" />
                                    <a
                                        href="tel:+1234567890"
                                        className="hover:text-gunmetal"
                                    >
                                        +1 (234) 567-890
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                {showButton && (
                    <button
                        onClick={scrollToTop}
                        className="fixed bottom-8 right-8 flex items-center justify-center bg-amber-600 text-white  rounded-full text-md w-10 h-10  shadow-lg hover:scale-110 hover:shadow-2xl transition-all duration-300 ease-in-out "
                        title="Scroll to top"
                    >
                        ▲
                    </button>
                )}
                <button
                    onClick={openWhatsApp}
                    className="fixed bottom-8 left-8 flex items-center justify-center bg-green-500 text-white rounded-full text-2xl w-11 h-11 shadow-lg hover:scale-110 hover:shadow-2xl transition-all duration-300 ease-in-out"
                    title="Chat on WhatsApp"
                >
                    <FaWhatsapp />
                </button>

                <hr className="my-6 border-graphite sm:mx-auto lg:my-8" />

                <div className="sm:flex sm:items-center sm:justify-between">
                    <div className="text-sm text-silver text-center sm:text-left">
                        &copy; {new Date().getFullYear()}{" "}
                        <span className="text-orange-300 text-sm">SpaceMaster Construction</span>. All rights
                        reserved.
                    </div>
                    <div className=" text-sm mt-2 sm:mt-0 text-sm text-silver text-center sm:text-right">
                        Site created by{" "}
                        <span className="font-normal text-orange-300 text-sm">
                            Streakify Technologies Pvt. Ltd.
                        </span>
                    </div>

                </div>
            </div>
        </footer >
    );
};

export default Footer;
