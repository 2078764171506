import React from 'react';
import { Phone, Mail, User, Briefcase, } from 'lucide-react';
import { FaArrowRight, FaBuilding, FaCheck, FaFacebook, FaInstagram, FaLinkedin, FaMoneyBill, FaMoneyBillAlt, FaMoneyCheckAlt, FaShieldAlt, FaSpeakap, FaWhatsapp } from 'react-icons/fa';

const AboutUs = () => {
    const testimonials = [
        {
            image: 'https://images.pexels.com/photos/1642228/pexels-photo-1642228.jpeg?auto=compress&cs=tinysrgb&w=400',
            name: 'Leslie Alexander',
            handle: '@lesliealexander',
            quote: '"SpaceMaster Construction did an outstanding job building our new home. Their attention to detail, commitment to quality, and on-time delivery truly set them apart. We couldn’t be happier with the final result!."'
        },
        {
            image: 'https://images.pexels.com/photos/769772/pexels-photo-769772.jpeg?auto=compress&cs=tinysrgb&w=400',
            name: 'Lindsay Walton',
            handle: '@lindsaywalton',
            quote: '"Working with SpaceMaster Construction on our new house was an absolute pleasure. They provided expert advice every step of the way, and the craftsmanship is second to none. We felt involved throughout the entire process, and the end result exceeded our expectations."'
        },
        {
            image: 'https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&w=400',
            name: 'Whitney Francis',
            handle: '@whitneyfrancis',
            quote: '"From the first consultation to the final walk-through, SpaceMaster Construction was a true partner in our home-building journey. Their team was professional, knowledgeable, and focused on making sure everything was perfect. Highly recommend them for any home construction needs."'
        },


    ];



    return (
        <>
            <section className="relative isolate py-16 sm:py-24 lg:py-32 font-serif">
                {/* Background */}
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{
                        backgroundImage: `linear-gradient(to bottom, 
            rgba(59, 48, 44, 0.8), 
            rgba(59, 48, 44, 0.8), 
            rgba(59, 48, 44, 0.8), 
            rgba(59, 48, 44, 0.8)),  
            url('/assets/images/villa3.jpg')`,
                        filter: "blur(0px)", // Adds the blur effect to the image.
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                ></div>


                <div className="relative max-w-7xl mx-auto px-7 lg:px-8 flex items-center justify-center h-full">
                    {/* "Get in Touch" Section */}
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center text-left lg:text-left">
                        {/* Content Section */}

                        {/* Repeat Content Section */}
                        <div className=''>
                            <h1 className="text-6xl text-orange-200 mt-12 font-black">About SpaceMaster</h1>

                            <p className="text-lg text-silver leading-relaxed mt-8">
                                We craft sustainable timber-framed homes, blending modern design with timeless quality, tailored to your unique lifestyle.
                            </p>

                        </div>
                    </div>
                </div>
            </section>











            <div class="relative min-h-screen overflow-hidden bg-orange-50 font-serif">

                <div class="relative mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
                    <div class="sm:max-w-lg">

                        <div className="max-w-lg mt-6">
                            <h6 className="text-amber-700 font-semibold mb-6">MORE ABOUT SPACEMASTER CONSTRUCTIONS</h6>
                            <h1 className="text-4xl sm:text-5xl font-serif mb-6">BUILDING YOUR DREAM HOME WITH EXPERTISE</h1>
                            <p className="text-gray-600 mb-8">
                                We specialize in creating homes that are not only functional but also beautiful. Our team of experienced architects and builders work tirelessly to bring your vision to life, ensuring the highest quality craftsmanship every step of the way.
                            </p>
                            <div className="mb-8">
                                <div className="flex items-center mb-4">
                                    <span className="text-amber-700 text-4xl font-bold">10+</span>
                                    <span className="ml-4 text-gray-600">Years Experience in Home Constructions</span>
                                </div>
                                <ul className="space-y-2">
                                    <li className="flex items-center text-gray-600">
                                        <span className="w-2 h-2 bg-amber-700 rounded-full mr-2"></span>
                                        Expertise in residential, commercial, and luxury homes
                                    </li>
                                    <li className="flex items-center text-gray-600">
                                        <span className="w-2 h-2 bg-amber-700 rounded-full mr-2"></span>
                                        Innovative design and eco-friendly construction methods
                                    </li>
                                </ul>
                            </div>
                            <div className="flex items-center">
                                <span className="text-2xl font-bold mr-4 text-darkCoffee">5K+ HAPPY CLIENTS</span>
                            </div>
                        </div>
                    </div>


                    <div>
                        <div class="mt-10">
                            <div
                                aria-hidden="true"
                                class="pointer-events-none lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl"
                            >
                                <div class="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                                    <div class="flex items-center space-x-6 lg:space-x-8">
                                        <div class="grid shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                                            <div class="h-64 w-44 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100">
                                                <img src="/assets/images/2.jpg" alt="Modern Home" class="size-full object-cover" />
                                            </div>

                                        </div>
                                        <div class="grid shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                                            <div class="h-64 w-44 overflow-hidden rounded-lg">
                                                <img src="/assets/images/13.jpeg" alt="Luxury Villa" class="size-full object-cover" />
                                            </div>
                                            <div class="h-64 w-44 overflow-hidden rounded-lg">
                                                <img src="/assets/images/4.jpg" alt="Apartment Complex" class="size-full object-cover" />
                                            </div>

                                        </div>
                                        <div class="grid shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                                            <div class="h-64 w-44 overflow-hidden rounded-lg">
                                                <img src="/assets/images/12.jpeg" alt="Corporate Office" class="size-full object-cover" />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <section className=" relative bg-darkCoffee py-16 font-serif">
                <div className="absolute top-0 left-0 w-full h-full bg-cover bg-fixed bg-center"
                    style={{ backgroundImage: 'linear-gradient(rgba(25, 23, 22, 0.9), rgba(25, 23, 22, 0.9)), url("/assets/images/mining.jpg")' }}></div>
                <div className="container relative mx-auto px-4">
                    <div className="text-center mb-12">
                        <h6 className="text-amber-700 mb-2">FEATURES OF SPACE MASTER CONSTRUCTIONS</h6>
                        <h2 className="text-5xl text-white mb-4">WHY CHOOSE US FOR YOUR DREAM HOME</h2>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                        {[
                            {
                                icon: <ClipboardList className="w-8 h-8" />,
                                title: "EXPERT DESIGN",
                                description: "Our team of skilled architects will work with you to design a home tailored to your vision."
                            },
                            {
                                icon: <Target className="w-8 h-8" />,
                                title: "QUALITY CONSTRUCTION",
                                description: "We ensure the highest construction standards with attention to detail at every phase."
                            },
                            {
                                icon: <Users className="w-8 h-8" />,
                                title: "COLLABORATIVE TEAMWORK",
                                description: "Our experienced team works together to deliver your dream home seamlessly."
                            },
                            {
                                icon: <Rocket className="w-8 h-8" />,
                                title: "ON-TIME DELIVERY",
                                description: "We pride ourselves on completing projects on time, without compromising on quality."
                            }
                        ].map((feature, index) => (
                            <div key={index} className="relative text-center">
                                <div className="bg-orange-100 rounded-full w-20 h-20 flex items-center justify-center mx-auto mb-4">
                                    <div className="text-orange-500">{feature.icon}</div>
                                </div>
                                <h3 className="text-white text-xl font-bold mb-2">{feature.title}</h3>
                                <p className="text-gray-400">{feature.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section> */}






            <section className="relative isolate overflow-hidden py-16 sm:py-24 lg:py-32 font-serif">
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{
                        backgroundImage:
                            "linear-gradient(rgba(25, 23, 22, 0.9), rgba(25, 23, 22, 0.9)), url('/assets/images/mining.jpg')",
                    }}
                ></div>

                <div className="relative mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 mx-auto lg:max-w-none lg:grid-cols-2">
                        {/* Left Section */}
                        <div className="max-w-xl lg:max-w-lg">
                            <h1 className="text-5xl font-serif mb-6 font-normal mb-4 text-white">
                                Delivering Quality & Value with Every Project
                            </h1>
                            <p className="mt-4 text-md text-silver">
                                Trust us to provide top-notch construction solutions with unbeatable pricing, guaranteed quality, and transparent processes.
                                Our team is dedicated to delivering excellence on every project, ensuring that your vision is brought to life with precision and care.
                            </p>


                            <div className="mt-6 flex max-w-md gap-x-4">
                                <label htmlFor="email-address" className="sr-only">
                                    Email address
                                </label>
                                <input
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm"
                                    placeholder="Enter your email"
                                />
                                <button
                                    type="submit"
                                    className="flex-none rounded-md bg-orange-400 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500"
                                >
                                    Subscribe
                                </button>
                            </div>


                        </div>
                        {/* Statistics Section with New Content */}
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-6 ml-6">
                            <div className="flex items-center drop-shadow-[0_0_10px_rgba(0,0,0,0.9)] transform -translate-x-6">
                                <div className="border border-orange-300 rounded-full p-3 mr-4 text-white">
                                    <FaBuilding />
                                </div>
                                <div>
                                    <p className="text-xl font-medium text-white"> Project Management</p>
                                    <p className="text-silver text-sm">Transforming complex processes into smooth experiences.</p>
                                </div>
                            </div>

                            <div className="flex items-center drop-shadow-[0_0_10px_rgba(0,0,0,0.9)] transform -translate-x-6">
                                <div className="border border-orange-300 rounded-full p-3 mr-4 text-white">
                                    <FaMoneyBillAlt />
                                </div>
                                <div>
                                    <p className="text-xl font-medium text-white">Affordable Solutions</p>
                                    <p className="text-silver text-sm">High-quality service that fits your budget.</p>
                                </div>
                            </div>

                            <div className="flex items-center drop-shadow-[0_0_10px_rgba(0,0,0,0.9)] transform -translate-x-6">
                                <div className="border border-orange-300 rounded-full p-3 mr-4 text-white">
                                    <FaCheck />
                                </div>
                                <div>
                                    <p className="text-xl font-medium text-white"> Quality Assurance</p>
                                    <p className="text-silver text-sm">Quality control at every stage of the project.</p>
                                </div>
                            </div>

                            <div className="flex items-center drop-shadow-[0_0_10px_rgba(0,0,0,0.9)] transform -translate-x-6">
                                <div className="border border-orange-300 rounded-full p-3 mr-4 text-white">
                                    <FaMoneyCheckAlt />
                                </div>
                                <div>
                                    <p className="text-xl font-medium text-white">Transparent Billing</p>
                                    <p className="text-silver text-sm">Pay only for what’s completed, with no hidden costs.</p>
                                </div>
                            </div>

                            <div className="flex items-center drop-shadow-[0_0_10px_rgba(0,0,0,0.9)] transform -translate-x-6">
                                <div className="border border-orange-300 rounded-full p-3 mr-4 text-white">
                                    <FaShieldAlt />
                                </div>
                                <div>
                                    <p className="text-xl font-medium text-white">On-Time Project Delivery</p>
                                    <p className="text-silver text-sm">Your deadlines are our priority, with robust safety measures in place.</p>
                                </div>
                            </div>

                            <div className="flex items-center drop-shadow-[0_0_10px_rgba(0,0,0,0.69] transform -translate-x-6">
                                <div className="border border-orange-300 rounded-full p-3 mr-4 text-white">
                                    <FaSpeakap />
                                </div>
                                <div>
                                    <p className="text-xl font-medium text-white">Clear Communication</p>
                                    <p className="text-silver text-sm">We keep you informed every step of the way.</p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>




            <div class="bg-orange-50 py-8 font-serif ">
                <div class="py-10 max-w-screen-lg mx-auto">
                    <div class="text-center mb-16">
                        <div className="text-amber-700 font-medium">— TEAM  —</div>
                        <h3 class="text-5xl font-serif mb-6 font-normal mb-4">
                            Our Team
                        </h3>
                        <p class="text-lg text-gray-600 mb-6">
                            Meet the dedicated professionals behind our home <br /> construction projects, committed to delivering quality, innovation, and excellence in every build.
                        </p>

                    </div>

                    <div class="p-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">

                        <div class="text-center bg-orange-100">
                            <img
                                class="w-full h-64 object-cover  "
                                src="https://images.pexels.com/photos/1680172/pexels-photo-1680172.jpeg?auto=compress&cs=tinysrgb&w=400"
                                alt="Mehdi Mohammadi"
                            />
                            <div class="p-6">
                                <h4 class="text-lg font-semibold text-gray-900 hover:text-indigo-600 transition">
                                    Bishwajith saho
                                </h4>
                                <p class="text-gray-500 uppercase text-sm">Site Supervisor</p>
                                <div class="mt-4 flex justify-center space-x-4 text-gray-400  text-gray-600">


                                    <FaFacebook className='text-blue-700' />
                                    <FaLinkedin className='text-blue-400' />
                                    <FaWhatsapp className='text-green-600' />
                                    <FaInstagram className='text-pink-600' />

                                </div>
                            </div>
                        </div>
                        <div class="text-center bg-orange-100">
                            <img
                                class="w-full h-64 object-cover"
                                src="https://images.pexels.com/photos/3778680/pexels-photo-3778680.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=1&amp;w=500"
                                alt="Mehdi Mohammadi"
                            />
                            <div class="p-6">
                                <h4 class="text-lg font-semibold text-gray-900 hover:text-orange-300 transition">
                                    Sukumar Dutta
                                </h4>
                                <p class="text-gray-500 uppercase text-sm">Project Manager</p>
                                <div class="mt-4 flex justify-center space-x-4 text-gray-400  text-gray-600">


                                    <FaFacebook className='text-blue-700' />
                                    <FaLinkedin className='text-blue-400' />
                                    <FaWhatsapp className='text-green-600' />
                                    <FaInstagram className='text-pink-600' />

                                </div>
                            </div>
                        </div>

                        <div class="text-center bg-orange-100">
                            <img
                                class="w-full h-64 object-cover  "
                                src="https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&w=400"
                                alt="Mehdi Mohammadi"
                            />
                            <div class="p-6">
                                <h4 class="text-lg font-semibold text-gray-900 hover:text-indigo-600 transition">
                                    Shashank Nathan
                                </h4>
                                <p class="text-gray-500 uppercase text-sm">Construction Engineer</p>
                                <div class="mt-4 flex justify-center space-x-4 text-gray-400  text-gray-600">


                                    <FaFacebook className='text-blue-700' />
                                    <FaLinkedin className='text-blue-400' />
                                    <FaWhatsapp className='text-green-600' />
                                    <FaInstagram className='text-pink-600' />

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className="relative py-8 font-serif">
                {/* Background image with gradient overlay */}
                <div
                    className="absolute top-0 left-0 w-full h-full bg-cover bg-fixed bg-center"
                    style={{
                        backgroundImage:
                            'linear-gradient(rgba(25, 23, 22, 0.8), rgba(25, 23, 22, 0.9)), url("/assets/images/11.jpeg")',
                    }}
                ></div>

                <div className="container mx-auto px-4 relative z-10">
                    <div className="max-w-2xl mx-auto text-center mb-12">
                        <h2 className="text-5xl font-serif mb-6 text-white">Testimonials</h2>
                        <p className="text-slate-200">
                            We have worked with thousands of amazing people
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {testimonials.map((testimonial, index) => (
                            <div
                                key={index}
                                className={`p-6 space-y-4 shadow-sm bg-white bg-opacity-20 backdrop-blur-md ${index % 2 === 0 ? 'bg-gray-100' : 'bg-gray-200'}`}
                            >
                                <div className="flex items-center space-x-4">
                                    <img
                                        src={testimonial.image}
                                        alt={testimonial.name}
                                        className="w-16 h-16 rounded-full"
                                    />
                                    <div>
                                        <h3 className="font-medium text-white">{testimonial.name}</h3>
                                        <p className="text-slate-300 text-sm">{testimonial.handle}</p>
                                    </div>
                                </div>
                                <p className="text-slate-100">{testimonial.quote}</p>
                            </div>
                        ))}

                    </div>
                </div>

                {/* Arrow icon at bottom-right */}
                <div className="absolute bottom-8 right-8 p-2 border border-white/20 rounded-full group-hover:border-orange-200/20">
                    <FaArrowRight className="w-4 h-4 text-white" />
                </div>
            </div>






            {/* contact */}
            < div
                className="bg-orange-50 bg-cover bg-center p-4 md:p-8"

            >
                {/* Main Contact Section */}
                < div className="max-w-7xl mx-auto p-8 bg-orange-50 rounded-lg " >
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">

                        {/* Left Section */}
                        <div className="flex flex-col justify-between">
                            <div>
                                <div className="text-orange-400 font-medium text-sm mb-4">— CONTACT US —</div>
                                <h2 className="text-5xl font-serif mb-6 font-normal mb-4">Talk To Our Experts</h2>
                                <p className="text-gray-600 mb-6">
                                    Connect with our team of professionals to discuss your needs and find tailored solutions. Rest assured, we'll provide the expertise and guidance you deserve.
                                </p>
                            </div>
                            <div className="space-y-2">
                                <div className="bg-orange-50 p-2 rounded-lg flex items-center space-x-4">
                                    <Phone className="text-orange-500 w-6 h-6" />
                                    <div>
                                        <h3 className="font-semibold text-lg">Phone Number</h3>
                                        <p className="text-gray-600">+91 1234567890</p>
                                    </div>
                                </div>
                                <div className="bg-orange-50 p-2 rounded-lg flex items-center space-x-4">
                                    <Mail className="text-orange-500 w-6 h-6" />
                                    <div>
                                        <h3 className="font-semibold text-lg">Email Address</h3>
                                        <p className="text-gray-600">info@spacemaster.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Right Section - Form */}
                        <div>
                            <form className="space-y-8">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div className="relative">
                                        <input
                                            type="text"
                                            placeholder="Name"
                                            className="w-full p-3 border border-orange-200 rounded-lg pl-10"
                                        />
                                        <User className="w-5 h-5 text-gray-400 absolute left-3 top-3" />
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="tel"
                                            placeholder="Phone Number"
                                            className="w-full p-3 border border-orange-200 rounded-lg pl-10"
                                        />
                                        <Phone className="w-5 h-5 text-gray-400 absolute left-3 top-3" />
                                    </div>
                                </div>

                                <div className="relative">
                                    <input
                                        type="email"
                                        placeholder="Email Address"
                                        className="w-full p-3 border border-orange-200 rounded-lg pl-10"
                                    />
                                    <Mail className="w-5 h-5 text-gray-400 absolute left-3 top-3" />
                                </div>

                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder="Location of Your Plot"
                                        className="w-full p-3 border border-orange-200 rounded-lg pl-10"
                                    />
                                    <Briefcase className="w-5 h-5 text-gray-400 absolute left-3 top-3" />
                                </div>

                                <button
                                    type="submit"
                                    className="w-full bg-orange-500 text-white py-3 px-6 rounded-lg hover:bg-orange-600 transition-colors"
                                >
                                    Book Free Consultation
                                </button>
                            </form>
                        </div>
                    </div>
                </div >





            </div >
        </>
    );
};

export default AboutUs;
