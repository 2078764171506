// src/Home.js
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Phone, Mail, User, Briefcase, } from 'lucide-react';

import { FaArrowRight, } from 'react-icons/fa';
import Homeconst from '../components/Homeconst';
import Services from './Services';


const Home = () => {



    // Define your stats
    const stats = [
        { label: 'Projects Completed', endValue: 400, suffix: '+' },
        { label: 'Satisfied Clients', endValue: 3000, suffix: 'k+' },
        { label: 'Years Experience', endValue: 10, suffix: '+' },
    ];
    const houses = [
        {
            image: 'https://sbnri.com/blog/wp-content/uploads/2021/02/ICICI-Home-Loan.jpg',

        },
        {
            image: 'https://5.imimg.com/data5/CG/TB/UV/SELLER-7923900/hdfc-home-loan-500x500.jpg', // Corrected image URL
        },
        {
            image: 'https://d3mbwbgtcl4x70.cloudfront.net/home_loans_by_sbi_aee5ef7be4.webp', // Valid direct image URL
        },
    ];
    const features = [
        {
            title: "CRN001",
            description: "Rajarajeshwari Nagar, Bengaluru",
            image: "/assets/images/11.jpeg"
        },
        {
            title: "CRN002",
            description: "Bannerghatta Main Road, Bengaluru",
            image: "/assets/images/12.jpeg"
        },
        {
            title: "CRN004",
            description: "Rajarajeshwari Nagar, Bengaluru",
            image: "/assets/images/13.jpeg"
        },
        {
            title: "CRN005",
            description: "Bannerghatta Main Road, Bengaluru.",
            image: "/assets/images/14.jpeg"
        },
        {
            title: "CRN004",
            description: "Rajarajeshwari Nagar, Bengaluru",
            image: "/assets/images/11.jpeg"
        },
        {
            title: "CRN005",
            description: "Bannerghatta Main Road, Bengaluru.",
            image: "/assets/images/12.jpeg"
        }
    ];

    // Create state to store the animated values
    const [animatedStats, setAnimatedStats] = useState([]);

    // Function to animate values
    const animateValue = (endValue) => {
        const duration = 4000; // Animation duration (2 seconds)
        const increment = endValue / (duration / 50); // Control animation speed
        let startValue = 0;

        return new Promise((resolve) => {
            const interval = setInterval(() => {
                startValue += increment;
                if (startValue >= endValue) {
                    startValue = endValue;
                    clearInterval(interval);
                    resolve(startValue);
                }
                setAnimatedStats((prevStats) =>
                    prevStats.map((stat) =>
                        stat.endValue === endValue
                            ? { ...stat, animatedValue: startValue }
                            : stat
                    )
                );
            }, 50);
        });
    };

    // Use effect to animate values
    useEffect(() => {
        // Initialize stats with zero values
        const initialStats = stats.map((stat) => ({
            ...stat,
            animatedValue: 0, // Set initial value as 0
        }));
        setAnimatedStats(initialStats);

        // Animate the stats
        stats.forEach((stat) => {
            animateValue(stat.endValue);
        });
    }, []);
    const services = [
        "Market Research",
        "Industrial manufacturing",
        "Pre-construction services",
        "Building and construction"
    ];
    const [expandedIndex, setExpandedIndex] = useState(null);

    const toggleFAQ = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };
    const [location, setLocation] = useState('BANGALORE');


    const steps = [
        {
            title: "Raise a Request",
            description: "Start by submitting your request to communicate your needs clearly.",

        },
        {
            title: "Talk to an Expert",
            description: " Connect with our experts for guidance and tailored insights.",

        },
        {
            title: "Book with Us",
            description: " Secure your spot easily by booking with us for a seamless and personalized experience.",

        },
        {
            title: "Schedule a Meeting.",
            description: " Arrange a meeting to discuss your vision and align ideas.",

        },
        {
            title: "Get Your Designs",
            description: "Receive customized design proposals that reflect your preferences.",

        },
        {
            title: "Settle In",
            description: "Move into your new home and enjoy the results of your efforts.",

        }
    ];
    const sections = [
        { title: 'Structure', expanded: false },
        { title: 'Kitchen', expanded: false },
        { title: 'Bathroom', expanded: false },
        { title: 'Doors & Windows', expanded: false },
        { title: 'Painting', expanded: false },
        { title: 'Flooring', expanded: false },
        { title: 'Electrical', expanded: false },
        { title: 'Miscellaneous', expanded: false },
        { title: 'Luxury Indulgences for the Space Enthusiast', expanded: false },
        { title: 'Home Automation including safety & security', expanded: false }
    ];
    const faqs = [
        {
            question: "What services do you offer?",
            answer: "We offer a wide range of services including residential construction, commercial construction, renovations, and project management. Our team is equipped to handle all aspects of construction from design to completion."
        },
        {
            question: "How long will my construction project take?",
            answer: "The duration of a construction project varies depending on its complexity, size, and scope. We provide a detailed timeline during the initial consultation to help you understand the process."
        },
        {
            question: "What is the estimated cost of a construction project?",
            answer: "The cost of a construction project can vary widely based on materials, labor, and design choices. We offer free estimates after assessing your project requirements during the consultation."
        },
        {
            question: "Do you offer warranties on your work?",
            answer: "Yes, we provide warranties on our work to ensure that you are satisfied with the results. The specifics of the warranty will be discussed during the contract phase."
        },
        {
            question: "Can I make changes to the design after construction begins?",
            answer: "While it is possible to make changes during construction, it may impact the timeline and budget. We encourage discussing any desired changes with our project manager as soon as possible."
        },
        {
            question: "What should I do if I have a concern during the construction process?",
            answer: "If you have any concerns during the construction process, please reach out to your assigned project manager immediately. We are committed to resolving issues quickly and effectively."
        }
    ];
    const projects = [
        {
            id: 'CRN001',
            location: 'Rajarajeshwari Nagar, Bengaluru',
            renderImage: '/assets/images/light.png',

        },
        {
            id: 'CRN002',
            location: 'Bannerghatta Main Road, Bengaluru',
            renderImage: '/assets/images/green.png',

        },

        {
            id: 'CRN004',
            location: 'Rajarajeshwari Nagar, Bengaluru',
            renderImage: '/assets/images/light.png',

        },
        {
            id: 'CRN005',
            location: 'Bannerghatta Main Road, Bengaluru',
            renderImage: '/assets/images/green.png',

        },
        {
            id: 'CRN003',
            location: 'Jigani, Bengaluru',
            renderImage: '/assets/images/img2.png',

        },
        // Add more project data as needed
    ];
    const services1 = [
        {
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
                </svg>
            ),
            title: "Industry Expertise",
            description: "We use the latest construction machinery to ensure quality and efficiency in every project."
        },
        {
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M3 21h18M9 8h1m-1 4h1m-1 4h1m4-8h1m-1 4h1m-1 4h1M4 21V5a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v16" />
                </svg>
            ),
            title: "Competitive Pricing",
            description: "We offer transparent and reasonable rates without compromising on quality and schedule."
        },
        {
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M5 12H3l9-9 9 9h-2M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-7" />
                    <path d="M9 21v-6a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v6" />
                </svg>
            ),
            title: "Timely Project Delivery",
            description: "We value your time and commit to delivering projects on schedule, every time.projects on schedule, every time."
        }
    ];
    const settings = {
        dots: true,
        infinite: true,
        speed: 200,
        slidesToShow: 4, // Show 4 slides at a time on large screens
        slidesToScroll: 1,
        autoplay: true, // Enable automatic sliding
        autoplaySpeed: 1000, // Slide every 3 seconds (3000ms)
        responsive: [
            {
                breakpoint: 1280, // Extra-large screens
                settings: {
                    slidesToShow: 4, // Show 4 slides on very large screens
                },
            },
            {
                breakpoint: 1024, // Large screens
                settings: {
                    slidesToShow: 3, // Show 3 slides on large screens
                },
            },
            {
                breakpoint: 768, // Medium screens
                settings: {
                    slidesToShow: 2, // Show 2 slides on medium screens
                },
            },
            {
                breakpoint: 640, // Small screens
                settings: {
                    slidesToShow: 1, // Show 1 slide on smaller screens
                },
            },
        ],
    };


    return (
        <div className="bg-darkCoffee text-graphite  font-serif">
            {/* Hero Section */}
            <section className="relative min-h-[107vh]  flex flex-col md:flex-row items-center justify-center px-6 md:px-10 text-white">
                {/* Background Image and Gradient Overlay */}
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{
                        backgroundImage:
                            "linear-gradient(rgba(25, 23, 22, 0.6), rgba(25, 23, 22, 0.8)), url('/assets/images/brick.jpg')", // Linear gradient with #191716 color and opacity
                    }}
                ></div>

                {/* Content */}
                <div className="relative z-10 text-center flex flex-col items-center justify-center w-full ">
                    <div className="text-orange-300 tracking-widest text-md mb-6">
                        — BUILDING THE FUTURE , ONE SPACE AT A TIME —
                    </div>

                    {/* Main heading with large distinctive font */}
                    <h1 className="text-white font-serif max-w-4xl mx-auto">
                        <span className="text-5xl font-serif mb-6 sm:text-4xl md:text-4xl lg:text-8xl leading-tight">
                            SpaceMaster Construction
                        </span>
                    </h1>
                    <button className="bg-orange-300 tracking-widest text-darkCoffee font-semibold py-2 px-5 rounded-full transition-colors shadow-lg hover:shadow-xl font-serif mt-8">
                        Let's Build
                    </button>
                </div>
            </section>























            {/* About Us */}
            <section className="w-full bg-orange-50 py-16">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 lg:grid-cols-2 gap-8 items-center overflow-hidden">
                    {/* Left Column - Images */}
                    <div className="relative grid grid-cols-2 gap-4 w-full">
                        {/* Main Image */}
                        <div className="col-span-2 rounded-3xl overflow-hidden relative">
                            <img
                                src="/assets/images/villa.jpg"
                                alt="Construction worker with tablet"
                                className="w-full h-auto object-cover"
                            />
                            <div className="absolute -left-0 -bottom-1 w-20 sm:w-24 md:w-28 h-20 sm:h-24 md:h-28">
                                <div className="relative w-full h-full rounded-full p-2 animate-spin-slow">
                                    <div className="relative w-full h-full rounded-full bg-white flex items-center justify-center">
                                        <svg className="absolute w-full h-full" viewBox="0 0 100 100">
                                            <path
                                                id="curve"
                                                d="M 50,50 m -37,0 a 37,37 0 1,1 74,0 a 37,37 0 1,1 -74,0"
                                                fill="transparent"
                                            />
                                            <text className="text-[8px] sm:text-xs">
                                                <textPath href="#curve" className="fill-gray-700">
                                                    · 10 YEARS · 65 PROJECTS · 120 CLIENTS
                                                </textPath>
                                            </text>
                                        </svg>
                                        {/* Center Icon */}
                                        <div className="absolute flex items-center justify-center bg-orange-300 rounded-full p-2 sm:p-4 shadow-lg">
                                            <svg
                                                viewBox="0 0 24 24"
                                                className="w-4 h-4 sm:w-6 sm:h-6 text-darkCoffee"
                                                fill="none"
                                                stroke="currentColor"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                    d="M3 10l9-7 9 7v11a2 2 0 01-2 2H5a2 2 0 01-2-2V10z"
                                                />
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                    d="M9 21V12h6v9"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Two Smaller Images */}
                        <div className="rounded-3xl overflow-hidden">
                            <img
                                src="/assets/images/villa3.jpg"
                                alt="Construction team reviewing plans"
                                className="w-full h-auto rounded-3xl object-cover"
                            />

                        </div>
                        <div className="relative">
                            <img
                                src="/assets/images/roof.jpg"
                                alt="Construction team meeting"
                                className="w-full h-auto rounded-3xl object-cover"
                            />
                            {/* Rotating Badge */}



                        </div>
                    </div>

                    {/* Right Column - Content */}
                    <div className="lg:pl-8 w-full">
                        <div className="space-y-6">
                            {/* About Us Heading */}
                            <div className="inline-block text-amber-700 font-medium text-lg">
                                — ABOUT US —
                            </div>

                            {/* Main Title */}
                            <h2 className="text-5xl font-serif mb-6"> Building Dreams, Shaping Futures with SpaceMaster. </h2>

                            {/* Description */}
                            <p className="text-gray-600 text-sm sm:text-base md:text-lg leading-relaxed">
                                At SpaceMaster Constructions, we specialize in crafting homes that are not just structures but a reflection of your aspirations. With cutting-edge innovation and an unwavering commitment to quality, we bring your dream spaces to life. Our expertise spans residential projects, luxury villas, modern apartments, and custom-built homes tailored to perfection.
                            </p>

                            {/* Services Section */}
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 pt-4">
                                {[
                                    "Custom Home Design",
                                    "Eco-Friendly Building Solutions",
                                    "Luxury Villas & Apartments",
                                    "On-Time Project Delivery",
                                    "Innovative Floor Plans",
                                    "Cost-Effective Construction",
                                    "Renovation & Remodeling",
                                    "Smart Home Integration",
                                ].map((service, index) => (
                                    <div key={index} className="flex items-center gap-2">
                                        <div className="w-8 h-8 rounded-full border border-amber-700 flex items-center justify-center">
                                            <FaArrowRight className="w-4 h-4 sm:w-5 sm:h-5 text-amber-600" />
                                        </div>
                                        <span className="text-sm sm:text-base lg:text-sm md:text-lg font-medium">{service}</span>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>
            </section>


            <section className="relative bg-charcoal text-silver py-10 font-serif ">
                <div className="max-w-screen-xl mx-auto px-4 sm:px-5 lg:px-8">
                    {/* Background image with gradient */}
                    <div
                        className="absolute inset-0 bg-cover bg-center"
                        style={{
                            backgroundImage:
                                "linear-gradient(rgba(25, 23, 22, 0.9), rgba(25, 23, 22, 0.9)), url('/assets/images/wood.jpg')",
                        }}
                    ></div>
                    {/* Header Section */}
                    <div className="relative text-center mb-20">
                        <div className="text-orange-300  mb-3">— WHY CHOOSE US —</div>
                        <h2 className="text-5xl font-serif mb-4 text-white">
                            Discover what sets us apart
                        </h2>
                        <div className="text-sm text-silver mt-4">
                            Our expertise, cutting-edge technology, and commitment to excellence set us apart from the competition.
                        </div>
                    </div>


                    {/* Services Cards */}
                    <div className="flex flex-wrap justify-center">
                        <div className="p-4 md:w-1/4 sm:w-1/2">
                            <div className="border border-gray-700 p-4 rounded transform transition duration-500 hover:scale-110">
                                <div className="flex justify-center mb-3">
                                    <img
                                        src="https://image3.jdomni.in/banner/13062021/58/97/7C/E53960D1295621EFCB5B13F335_1623567851299.png?output-format=webp"
                                        className="w-24 filter text-orange-200"
                                        alt="State-of-the-Art Equipment"
                                    />
                                </div>
                                <h2 className="font-regular text-xl text-white">State-of-the-Art Equipment</h2>
                                <p className="mt-2 text-sm text-gray-300">
                                    We use the latest construction machinery to ensure quality and efficiency in every project.
                                </p>
                            </div>
                        </div>

                        <div className="p-4 md:w-1/4 sm:w-1/2">
                            <div className="border border-gray-700 p-4 rounded transform transition duration-500 hover:scale-110">
                                <div className="flex justify-center mb-3">
                                    <img
                                        src="https://image2.jdomni.in/banner/13062021/3E/57/E8/1D6E23DD7E12571705CAC761E7_1623567977295.png?output-format=webp"
                                        className="w-24 filter text-orange-200"
                                        alt="Competitive Pricing"
                                    />
                                </div>
                                <h2 className="font-regular text-xl text-white">Competitive Pricing</h2>
                                <p className="mt-2 text-sm text-gray-300">
                                    We offer transparent and reasonable rates without compromising on quality and schedule.
                                </p>
                            </div>
                        </div>

                        <div className="p-4 md:w-1/4 sm:w-1/2">
                            <div className="border border-gray-700 p-4 rounded transform transition duration-500 hover:scale-110">
                                <div className="flex justify-center mb-3">
                                    <img
                                        src="https://image3.jdomni.in/banner/13062021/16/7E/7E/5A9920439E52EF309F27B43EEB_1623568010437.png?output-format=webp"
                                        className="w-24 filter text-orange-200"
                                        alt="Timely Project Delivery"
                                    />
                                </div>
                                <h2 className="font-regular text-xl text-white">Timely Project Delivery</h2>
                                <p className="mt-2 text-sm text-gray-300">
                                    We value your time and commit to delivering projects on schedule, every time.
                                </p>
                            </div>
                        </div>

                        <div className="p-4 md:w-1/4 sm:w-1/2">
                            <div className="border border-gray-700 p-4 rounded transform transition duration-500 hover:scale-110">
                                <div className="flex justify-center mb-3">
                                    <img
                                        src="https://image3.jdomni.in/banner/13062021/EB/99/EE/8B46027500E987A5142ECC1CE1_1623567959360.png?output-format=webp"
                                        className="w-24 filter text-orange-200"
                                        alt="Industry Expertise"
                                    />
                                </div>
                                <h2 className="font-regular text-xl text-white">Industry Expertise</h2>
                                <p className="mt-2 text-sm text-gray-300">
                                    Our team of seasoned professionals brings years of experience in the construction industry.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </section>








            {/* how it works */}
            <div className="bg-orange-50 px-4 py-16 min-h-screen">
                <div className="max-w-6xl mx-auto">
                    <div className="text-center mb-12">
                        <div className="text-amber-700 font-medium">— HOW IT WORKS —</div>
                        <h1 className="text-5xl font-serif mb-6">Build your house. Step by step</h1>
                        <p className="text-gray-600 max-w-3xl mx-auto">
                            SpaceMaster Constructions specializes in creating homes that blend modern design, functionality, and sustainability. Our team works closely with clients to deliver custom solutions, ensuring quality and durability. Whether it's a luxury villa or a custom home, we focus on craftsmanship and long-lasting results.
                        </p>


                    </div>

                    <div className="grid md:grid-cols-2 gap-6 items-center">
                        <div className="relative">
                            <div className="bg-orange-300 p-2">
                                <div className="relative">
                                    <img
                                        src="/assets/images/13.jpeg"
                                        alt="Construction worker"
                                        className="w-auto h-auto object-cover object-left drop-shadow-[0_0_10px_rgba(0,0,0,0.6)] transform translate-x-[3%] translate-y-[1%] shadow-lg grayscale hover:grayscale-0 transition duration-300"
                                    />

                                </div>
                            </div>

                        </div>

                        <div class="p-4 max-w-xl mx-auto">



                            <div class="flex">
                                <div class="mr-4 flex flex-col items-center">
                                    <div>
                                        <div class="flex h-10 w-10 items-center justify-center rounded-full border-2 border-darkCoffee"><svg
                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="h-6 w-6 text-orange-400 ">
                                            <path d="M12 5l0 14"></path>
                                            <path d="M18 13l-6 6"></path>
                                            <path d="M6 13l6 6"></path>
                                        </svg></div>
                                    </div>
                                    <div class="h-full w-px bg-gray-300 dark:bg-slate-500"></div>
                                </div>
                                <div class="pt-1 pb-8">
                                    <p class="mb-2 text-xl font-bold text-gray-700">Raise a Request</p>
                                    <p class="text-gray-600 ">Start by submitting your request to communicate your needs clearly.</p>
                                </div>
                            </div>


                            <div class="flex">
                                <div class="mr-4 flex flex-col items-center">
                                    <div>
                                        <div class="flex h-10 w-10 items-center justify-center rounded-full border-2  border-darkCoffee"><svg
                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="h-6 w-6 text-orange-400">
                                            <path d="M12 5l0 14"></path>
                                            <path d="M18 13l-6 6"></path>
                                            <path d="M6 13l6 6"></path>
                                        </svg></div>
                                    </div>
                                    <div class="h-full w-px bg-gray-300 dark:bg-slate-500"></div>
                                </div>
                                <div class="pt-1 pb-8">
                                    <p class="mb-2 text-xl font-bold text-gray-700">Talk to an Expert</p>
                                    <p class="text-gray-600 ">Connect with our experts for guidance and tailored insights.</p>
                                </div>
                            </div>


                            <div class="flex">
                                <div class="mr-4 flex flex-col items-center">
                                    <div>
                                        <div class="flex h-10 w-10 items-center justify-center rounded-full border-2  border-darkCoffee"><svg
                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="h-6 w-6 text-orange-400">
                                            <path d="M12 5l0 14"></path>
                                            <path d="M18 13l-6 6"></path>
                                            <path d="M6 13l6 6"></path>
                                        </svg></div>
                                    </div>
                                    <div class="h-full w-px bg-gray-300 dark:bg-slate-500"></div>
                                </div>
                                <div class="pt-1 pb-8">
                                    <p class="mb-2 text-xl font-bold text-gray-700">Book with Us</p>
                                    <p class="text-gray-600">Secure your spot easily by booking with us for a seamless and personalized experience.</p>
                                </div>
                            </div>
                            <div class="flex">
                                <div class="mr-4 flex flex-col items-center">
                                    <div>
                                        <div class="flex h-10 w-10 items-center justify-center rounded-full border-2  border-darkCoffee"><svg
                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="h-6 w-6 text-orange-400">
                                            <path d="M12 5l0 14"></path>
                                            <path d="M18 13l-6 6"></path>
                                            <path d="M6 13l6 6"></path>
                                        </svg></div>
                                    </div>
                                    <div class="h-full w-px bg-gray-300 dark:bg-slate-500"></div>
                                </div>
                                <div class="pt-1 pb-8">
                                    <p class="mb-2 text-xl font-bold text-gray-700">Schedule a Meeting</p>
                                    <p class="text-gray-600 ">Arrange a meeting to discuss your vision and align ideas.</p>
                                </div>
                            </div>
                            <div class="flex">
                                <div class="mr-4 flex flex-col items-center">
                                    <div>
                                        <div class="flex h-10 w-10 items-center justify-center rounded-full border-2  border-darkCoffee"><svg
                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="h-6 w-6 text-orange-400">
                                            <path d="M12 5l0 14"></path>
                                            <path d="M18 13l-6 6"></path>
                                            <path d="M6 13l6 6"></path>
                                        </svg></div>
                                    </div>
                                    <div class="h-full w-px bg-gray-300 dark:bg-slate-500"></div>
                                </div>
                                <div class="pt-1 pb-8">
                                    <p class="mb-2 text-xl font-bold text-gray-700">Get Your Designs</p>
                                    <p class="text-gray-600 ">Receive customized design proposals that reflect your preferences.</p>
                                </div>
                            </div>



                            <div class="flex">
                                <div class="mr-4 flex flex-col items-center">
                                    <div>
                                        <div
                                            class="flex h-10 w-10 items-center justify-center rounded-full border-2 border-darkCoffee bg-darkCoffee">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                class="h-6 w-6 text-orange-400">
                                                <path d="M5 12l5 5l10 -10"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div class="pt-1 ">
                                    <p class="mb-2 text-xl font-bold text-gray-700">Settle In</p>
                                </div>
                            </div>


                        </div>


                    </div>
                </div>
            </div>











            <div className="bg-darkCoffee bg-opacity-60 backdrop-blur-lg px-4 py-16 font-serif relative overflow-x-hidden">
                <Services />
                <div className="max-w-6xl mx-auto">
                    {/* Header Section */}
                    <div className="  grid md:grid-cols-2 gap-12 mb-16">

                        <h2 className="text-5xl font-serif mb-2 text-white "> Completed Projects</h2>
                        <div className="space-y-6">
                            <p className="  text-gray-400 leading-relaxed ">
                                Every creation reflects our commitment to quality. We combine fresh ideas, accuracy, and careful attention to detail to deliver results that go beyond expectations, offering great value to our clients
                            </p>
                            <a href='/our-project'
                                className="flex items-center gap-2 text-amber-600 hover:text-orange-200 transition-colors group">
                                More Projects
                                <div className="p-2 border border-white/20 rounded-full group-hover:border-orange-200/20">
                                    <FaArrowRight className="w-4 h-4" />
                                </div>
                            </a>
                        </div>
                    </div>

                    {/* Slider Section */}
                    <Slider {...settings} className="mt-8">
                        {features.map((feature, index) => (
                            <div key={index} className="px-6">
                                <div className="space-y-4">
                                    <div className="aspect-square rounded-full overflow-hidden mt-6">
                                        <img
                                            src={feature.image}
                                            alt={feature.title}
                                            className="w-full h-full object-cover"
                                        />
                                    </div>
                                    <h3 className="text-xl font-medium text-white text-center">{feature.title}</h3>
                                    <p className="text-gray-400 text-sm text-center">{feature.description}</p>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>





            <Homeconst />






            {/* Banking Partners */}
            <div className="bg-[#2c2420] bg-opacity-60 backdrop-blur-lg px-4 py-16 font-serif relative">
                <div className="absolute top-0 left-0 w-full h-full bg-cover bg-fixed bg-center"
                    style={{ backgroundImage: 'linear-gradient(rgba(25, 23, 22, 0.5), rgba(25, 23, 22, 0.9)), url("/assets/images/interior.jpg")' }}></div>
                <div className="max-w-6xl mx-auto relative z-10">
                    <h1 className="text-5xl font-serif mb-6 text-white text-center">
                        Our Banking Partners
                    </h1>
                    <p className="text-gray-300 mb-6 text-center">
                        We are proud to collaborate with these trusted banking institutions to bring you the best financial services.
                    </p>
                    <div className="grid md:grid-cols-3 gap-6">
                        {houses.map((house, index) => (
                            <div
                                key={index}
                                className={`p-8 ${index < houses.length - 1 ? 'border-r border-orange-100 ' : ''}`}
                            >
                                {/* Display image */}
                                <div className="w-full h-full flex justify-center items-center">
                                    <img
                                        src={house.image}
                                        alt="Banking Partner"
                                        className="object-contain h-24 mx-auto" // Adjust size and style as needed
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>



            {/* contact */}

            <div
                className="bg-orange-50 bg-cover bg-center p-4 md:p-8"

            >
                {/* Main Contact Section */}
                <div className="max-w-7xl mx-auto p-8 bg-orange-50 rounded-lg ">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">

                        {/* Left Section */}
                        <div className="flex flex-col justify-between">
                            <div>
                                <div className="text-amber-700 font-medium text-sm mb-4">— CONTACT US —</div>
                                <h2 className="text-5xl font-serif mb-6 font-normal mb-4">Talk To Our Experts</h2>
                                <p className="text-gray-600 mb-6">
                                    Connect with our team of professionals to discuss your needs and find tailored solutions. Rest assured, we'll provide the expertise and guidance you deserve.
                                </p>
                            </div>
                            <div className="space-y-2">
                                <div className="bg-orange-50 p-2 rounded-lg flex items-center space-x-4">
                                    <Phone className="text-orange-500 w-6 h-6" />
                                    <div>
                                        <h3 className="font-semibold text-lg">Phone Number</h3>
                                        <p className="text-gray-600">+91 1234567890</p>
                                    </div>
                                </div>
                                <div className="bg-orange-50 p-2 rounded-lg flex items-center space-x-4">
                                    <Mail className="text-orange-500 w-6 h-6" />
                                    <div>
                                        <h3 className="font-semibold text-lg">Email Address</h3>
                                        <p className="text-gray-600">info@spacemaster.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Right Section - Form */}
                        <div>
                            <form className="space-y-8">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div className="relative">
                                        <input
                                            type="text"
                                            placeholder="Name"
                                            className="w-full p-3 border border-orange-200 rounded-lg pl-10"
                                        />
                                        <User className="w-5 h-5 text-gray-400 absolute left-3 top-3" />
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="tel"
                                            placeholder="Phone Number"
                                            className="w-full p-3 border border-orange-200 rounded-lg pl-10"
                                        />
                                        <Phone className="w-5 h-5 text-gray-400 absolute left-3 top-3" />
                                    </div>
                                </div>

                                <div className="relative">
                                    <input
                                        type="email"
                                        placeholder="Email Address"
                                        className="w-full p-3 border border-orange-200 rounded-lg pl-10"
                                    />
                                    <Mail className="w-5 h-5 text-gray-400 absolute left-3 top-3" />
                                </div>

                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder="Location of Your Plot"
                                        className="w-full p-3 border border-orange-200 rounded-lg pl-10"
                                    />
                                    <Briefcase className="w-5 h-5 text-gray-400 absolute left-3 top-3" />
                                </div>

                                <button
                                    type="submit"
                                    className="w-full bg-orange-500 text-white py-3 px-6 rounded-lg hover:bg-orange-600 transition-colors"
                                >
                                    Book Free Consultation
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


















            {/* cta */}


            <section className="relative isolate overflow-hidden py-10 sm:py-24 lg:py-32 font-serif">
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{
                        backgroundImage:
                            "linear-gradient(rgba(25, 23, 22, 0.9), rgba(25, 23, 22, 0.9)), url('/assets/images/wood.jpg')",
                    }}
                ></div>
                <div className="relative mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 mx-auto lg:max-w-none lg:grid-cols-2">
                        {/* Left Section */}
                        <div className="max-w-xl lg:max-w-lg">
                            <h1 className="text-5xl font-serif mb-6 font-normal mb-4 text-white">
                                Stay Updated on Our Construction Projects
                            </h1>
                            <p className="mt-4 text-md text-gray-300">
                                Receive insights into our latest projects, construction updates, and
                                innovative solutions straight to your inbox. Join us as we build the
                                future, one structure at a time.
                            </p>
                            <div className="mt-6 flex max-w-md gap-x-4">
                                <label htmlFor="email-address" className="sr-only">
                                    Email address
                                </label>
                                <input
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm"
                                    placeholder="Enter your email"
                                />
                                <button
                                    type="submit"
                                    className="flex-none rounded-md bg-orange-400 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500"
                                >
                                    Subscribe
                                </button>
                            </div>

                            {/* Statistics Section */}
                            <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-6 ml-6">
                                <div className="flex items-center drop-shadow-[0_0_10px_rgba(0,0,0,0.6)] transform -translate-x-6">
                                    <div className="  border border-orange-300 rounded-full p-3 mr-4">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6 text-white"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                                            />
                                        </svg>
                                    </div>
                                    <div>
                                        <p className="text-xl font-medium text-orange-100">7000+</p>
                                        <p className="text-silver">Homes</p>
                                    </div>
                                </div>

                                <div className="flex items-center drop-shadow-[0_0_10px_rgba(0,0,0,0.6)] transform -translate-x-6">
                                    <div className="border border-orange-300 rounded-full p-3 mr-4">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6 text-white"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                                            />
                                        </svg>
                                    </div>
                                    <div>
                                        <p className="text-xl font-medium text-orange-100">470+</p>
                                        <p className="text-silver">Quality Checks</p>
                                    </div>
                                </div>
                                <div className="flex items-center drop-shadow-[0_0_10px_rgba(0,0,0,0.6)] transform -translate-x-6">
                                    <div className="border border-orange-300 rounded-full p-3 mr-4">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6 text-white"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                                            />
                                        </svg>

                                    </div>
                                    <div>
                                        <p className="text-xl font-medium text-orange-100">270+</p>
                                        <p className="text-silver">Satisfied Clients</p>
                                    </div>
                                </div>

                                <div className="flex items-center drop-shadow-[0_0_10px_rgba(0,0,0,0.6)] transform -translate-x-6">
                                    <div className="border border-orange-300 rounded-full p-3 mr-4">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6 text-white"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                                            />
                                        </svg>
                                    </div>
                                    <div>
                                        <p className="text-xl font-medium text-orange-100">100%</p>
                                        <p className="text-silver">Safe Money Transaction</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Right Section */}
                        <div className="mt-8 lg:mt-0 lg:block hidden">
                            <img
                                src="/assets/images/sts.png"
                                alt="House Construction"
                                className="w-full h-full object-cover object-left drop-shadow-[0_0_10px_rgba(0,0,0,0.6)] transform -translate-x-6"
                            />
                        </div>

                    </div>
                </div>
            </section>













            {/* faq */}

            <section className="py-24 px-8 flex flex-col bg-orange-50 md:flex-row gap-12 w-full font-serif">
                {/* Title Section */}
                <div className="relative flex flex-col text-left basis-1/2 md:ml-16 px-10">
                    <p className="text-5xl sm:text-5xl font-normal mb-4 leading-tight text-gray-900">
                        Frequently Asked
                        <br />
                        Questions
                    </p>

                    {/* Falling Balls Container */}
                    <div className="absolute top-0 left-0 w-full h-full">
                        <div className="ball ball-1"></div>
                        <div className="ball ball-2"></div>
                        <div className="ball ball-3"></div>
                        <div className="ball ball-4"></div>
                        <div className="ball ball-5"></div>
                    </div>


                </div>

                {/* FAQ Section */}
                <ul className="basis-1/2 px-4">
                    {faqs.map((faq, index) => (
                        <li key={index} className="border-t border-orange-500">
                            {/* FAQ Question */}
                            <button
                                className="relative flex items-center justify-between w-full py-5 text-left text-gray-800 font-medium transition-colors hover:text-gray-900 focus:outline-none"
                                aria-expanded={expandedIndex === index}
                                aria-controls={`faq-answer-${index}`}
                                onClick={() => toggleFAQ(index)}
                            >
                                <span className=''>{faq.question}</span>
                                <svg
                                    className={`w-5 h-5 transition-transform duration-300 ease-in-out ${expandedIndex === index ? "rotate-90" : "rotate-0"
                                        }`}
                                    viewBox="0 0 16 16"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect y="7" width="16" height="2" rx="1"></rect>
                                    <rect
                                        y="7"
                                        width="16"
                                        height="2"
                                        rx="1"
                                        className="transform origin-center rotate-90"
                                    ></rect>
                                </svg>
                            </button>

                            {/* FAQ Answer */}
                            <div
                                id={`faq-answer-${index}`}
                                className={`transition-all duration-300 ease-in-out overflow-hidden ${expandedIndex === index ? "max-h-40" : "max-h-0"
                                    }`}
                            >
                                <div className="py-4 text-gray-700">
                                    <p className="leading-relaxed">{faq.answer}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </section>









        </div>
    );
};

export default Home;
