import React, { useState } from 'react';
import { Phone, Mail, User, Briefcase, PhoneCall, } from 'lucide-react';
import { FaArrowRight } from 'react-icons/fa';
export default function CostEstimator() {
    const [expandedIndex, setExpandedIndex] = useState(null);

    const toggleFAQ = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };
    const faqs = [
        {
            question: "How accurate are the cost estimates provided by the Spacemaster calculator?",
            answer: "The price per square foot varies depending on the materials, design, and location of your construction. We offer competitive pricing based on your specific requirements, which can be discussed in detail during your consultation."
        },
        {
            question: "What if I prefer to use a different brand or type of material than what’s suggested in the package",
            answer: "We understand that each client may have different preferences for materials. You can choose your own materials, but this may affect the overall cost and timeline. We’ll help you adjust the estimate accordingly."
        },
        {
            question: "What is the price per square foot to construct a home with Spacemaster?",
            answer: "The price per square foot varies depending on the materials, design, and location of your construction. We offer competitive pricing based on your specific requirements, which can be discussed in detail during your consultation."
        },
        {
            question: "What if I prefer to use a different brand or type of material than what’s suggested in the package?",
            answer: "We understand that each client may have different preferences for materials. You can choose your own materials, but this may affect the overall cost and timeline. We’ll help you adjust the estimate accordingly."
        },
        {
            question: "Who can I contact if I have more questions or need further assistance?",
            answer: "Yes, we handle all necessary permits and approvals as part of your construction project, ensuring everything is compliant with local regulations."
        },
        {
            question: "Does Spacemaster cover the necessary permits and approvals for my project",
            answer: "We follow a strict quality assurance process throughout the construction process, including material inspections, on-site quality checks, and ongoing monitoring by experienced supervisors."
        },
        {
            question: "Does your construction package include external walls and enclosures?",
            answer: "Yes, external walls and enclosures are typically included in our packages. However, we recommend discussing your project details with us to ensure everything you need is covered."
        },
        {
            question: "Do you offer financing or loans for construction projects?",
            answer: "Spacemaster does not directly offer loans but can assist in connecting you with trusted financial partners who provide home construction loans."
        },
        {
            question: "Is it necessary to pay the full amount before work begins on the construction project?",
            answer: "Full payment is not required upfront. We have a structured payment plan based on the project milestones, with payments due as work progresses to ensure transparency and satisfaction throughout the process."
        },
        {
            question: "How much time does it take to construct a full house with Spacemaster?",
            answer: "The time to complete a full house can vary depending on the design and size of the project. Generally, it takes between 12 to 18 months for a full residential building, including both construction and finishing."
        }
    ];

    return (
        <>
            <div>
                <section className="relative isolate py-16 sm:py-24 lg:py-32 font-serif">
                    {/* Background */}
                    <div
                        className="absolute inset-0 bg-cover bg-center"
                        style={{
                            backgroundImage: `linear-gradient(to bottom, 
            rgba(59, 48, 44, 0.9), 
            rgba(59, 48, 44, 0.9), 
            rgba(59, 48, 44, 0.7), 
            rgba(59, 48, 44, 0.8)),  
            url('/assets/images/wood1.jpg')`,
                            filter: "blur(0px)", // Adds the blur effect to the image.
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    ></div>

                    <div className="relative max-w-7xl mx-auto px-7 lg:px-8 flex items-center justify-center h-full">
                        {/* "Get in Touch" Section */}
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center text-left lg:text-left">
                            {/* Content Section */}

                            {/* Repeat Content Section */}
                            <div className=''>
                                <h1 className="text-6xl text-orange-200 mt-12 font-black">Building Cost Estimator</h1>

                                <p className="text-lg text-white leading-relaxed mt-8">
                                    Our team of experts is dedicated to designing sustainable, high-quality prefab timber-framed houses tailored to your lifestyle.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>






                <div className="flex flex-col md:flex-row justify-start items-start min-h-screen bg-amber-50 font-serif p-4 md:p-6">
                    {/* Form Section */}
                    <div className="max-w-4xl mx-auto md:mx-4 p-4 md:p-6 lg:p-8 bg-orange-100 w-full">
                        <h1 className="text-5xl md:text-4xl lg:text-5xl mb-4 text-gray-900">
                            House Construction Cost Calculator
                        </h1>

                        <p className="text-gray-700 mb-6 lg:mb-8 text-sm md:text-base">
                            Fill out the form below to get an estimate of house construction costs. Speak to our technical expert for a more accurate pricing.
                        </p>

                        <form className="space-y-6">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
                                {/* Mobile Number Input */}
                                <div className="flex items-center border rounded-md bg-white">
                                    <div className="px-3 py-2 border-r text-gray-500">
                                        <PhoneCall />
                                    </div>
                                    <input
                                        type="tel"
                                        placeholder="Mobile Number*"
                                        className="w-full px-4 py-2 focus:outline-none"
                                    />
                                </div>

                                {/* Name Input */}
                                <input
                                    type="text"
                                    placeholder="Name*"
                                    className="w-full px-4 py-2 border rounded-md focus:outline-none"
                                />

                                {/* Location Input */}
                                <input
                                    type="text"
                                    placeholder="Location of your Plot*"
                                    className="w-full px-4 py-2 border rounded-md focus:outline-none"
                                />

                                {/* Super Built Up Area Input */}
                                <input
                                    type="text"
                                    placeholder="Super Built Up Area (sq.ft)*"
                                    className="w-full px-4 py-2 border rounded-md focus:outline-none"
                                />

                                {/* Car Parking Dropdown */}
                                <select
                                    className="w-full px-4 py-2 border rounded-md focus:outline-none"
                                    defaultValue="01"
                                >
                                    <option value="01">No Of Car Parking (130 sq.ft/unit)* 01</option>
                                    <option value="02">02</option>
                                    <option value="03">03</option>
                                </select>

                                {/* Balcony Area Input */}
                                <input
                                    type="text"
                                    placeholder="Balcony & Utility Area (sq.ft)*"
                                    className="w-full px-4 py-2 border rounded-md focus:outline-none"
                                />
                            </div>

                            <button
                                type="submit"
                                className="w-full bg-amber-700 hover:bg-orange-600 text-white py-3 rounded-md flex items-center justify-center gap-2 transition-colors"
                            >
                                ESTIMATE COST
                                <FaArrowRight />
                            </button>

                            {/* Disclaimer */}
                            <div className="text-sm text-gray-600 mt-6">
                                <p className="font-semibold mb-2">Disclaimer:</p>
                                <p >
                                    The estimated costs provided are approximate and may differ based on your city or specific requirements. For precise calculations, we recommend consulting our technical experts or visiting the SpaceMasterConstructions office. Please note that the estimate applies to the construction area only and excludes compound wall costs.
                                </p>

                            </div>
                        </form>
                    </div>

                    {/* Content Section */}
                    <section className="py-2 px-4 font-serif w-full mt-6 md:mt-0 md:ml-4 rounded-md">
                        <div className="max-w-4xl mx-auto text-center md:text-left">
                            {/* First Image */}
                            <img
                                src="/assets/images/handhome.png"
                                alt="Home Construction Cost Calculator"
                                className="w-full h-full "
                            />

                            {/* Heading */}
                            <h2 className="text-4xl md:text-3xl lg:text-4xl text-gray-800 mb-4">
                                Get Accurate Home Construction Cost Estimates
                            </h2>

                            {/* Paragraph */}
                            <p className="text-sm md:text-base text-gray-600 mt-6 text-left">
                                Planning to build your dream home? Let our Home Construction Cost Calculator help you calculate the estimated cost for your project. Whether you're working with a professional contractor or managing the project yourself, our tool provides precise estimates for various construction costs, including built-up area, car parking, and balcony or utility spaces.
                            </p>


                        </div>
                    </section>

                </div>



                <section className="py-10 px-4 bg-orange-50 font-serif">
                    <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-start gap-8">
                        {/* Left Side - Text Content */}
                        <div className="w-full md:w-1/2">
                            {/* Section Heading */}
                            <h2 className="text-4xl md:text-4xl text-gray-800 mb-6">
                                Bring Your Dream Home to Life
                            </h2>

                            {/* Paragraph */}
                            <p className="text-md md:text-lg text-gray-700 mb-8 leading-relaxed">
                                Ready to take the first step in building your dream home? Brick & Bolt’s House Construction Cost Calculator is your trusted companion for estimating construction costs. Whether you're a seasoned contractor or embarking on your first project, this tool provides an accurate breakdown of expenses, including built-up area costs, car parking fees, and balcony or utility charges. Stay on top of your budget and ensure a smooth project completion with our easy-to-use cost estimator.
                            </p>

                            {/* Why Use the Calculator */}
                            <div className="p-6">
                                <h3 className="text-3xl md:text-2xl text-amber-700 mb-4 relative before:content-['☆'] before:text-amber-700 before:font-bold before:mr-2 before:absolute before:-left-6">
                                    Why Use Our Construction Cost Estimator?
                                </h3>
                                <ul className="space-y-4 text-base text-gray-700">
                                    <li>
                                        <strong>Tailored Inputs:</strong> Customize the calculator based on your project needs, whether you're building a cozy home or a luxurious villa.
                                    </li>
                                    <li>
                                        <strong>Precise Costing:</strong> Access reliable estimates backed by up-to-date pricing data for accurate planning.
                                    </li>
                                    <li>
                                        <strong>Quick Insights:</strong> Get immediate results that allow you to plan confidently and efficiently.
                                    </li>
                                </ul>
                            </div>

                            {/* How to Use */}


                        </div>

                        {/* Right Side - Images */}
                        <div className="w-full md:w-1/2 flex flex-col space-y-6">
                            <img
                                src="/assets/images/4.jpg"
                                alt="Construction planning"
                                className=""
                            />
                            <div className="p-6 mt-8">
                                <h3 className="text-3xl md:text-2xl text-amber-700 mb-4 relative before:content-['☆'] before:text-amber-700 before:font-bold before:mr-2 before:absolute before:-left-6">
                                    How to Use the Cost Calculator
                                </h3>
                                <ol className="list-decimal list-inside space-y-3 text-base text-gray-700">
                                    <li>Enter your location’s pin code from the dropdown.</li>
                                    <li>Specify the super built-up area in square feet.</li>
                                    <li>Select the required number of car parking spaces.</li>
                                    <li>Input the balcony and utility area measurements.</li>
                                    <li>Click "Estimate Cost" for detailed pricing.</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>









                {/* contact */}




            </div >

            < div
                className="bg-orange-50 bg-cover bg-center p-4 md:p-8"

            >
                {/* Main Contact Section */}
                < div className="max-w-7xl mx-auto p-8 bg-orange-50 rounded-lg " >
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">

                        {/* Left Section */}
                        <div className="flex flex-col justify-between">
                            <div>
                                <div className="text-amber-700 font-medium text-sm mb-4">— CONTACT US —</div>
                                <h2 className="text-5xl font-serif mb-6 font-normal mb-4">Talk To Our Experts</h2>
                                <p className="text-gray-600 mb-6">
                                    Connect with our team of professionals to discuss your needs and find tailored solutions. Rest assured, we'll provide the expertise and guidance you deserve.
                                </p>
                            </div>
                            <div className="space-y-2">
                                <div className="bg-orange-50 p-2 rounded-lg flex items-center space-x-4">
                                    <Phone className="text-orange-500 w-6 h-6" />
                                    <div>
                                        <h3 className="font-semibold text-lg">Phone Number</h3>
                                        <p className="text-gray-600">+91 1234567890</p>
                                    </div>
                                </div>
                                <div className="bg-orange-50 p-2 rounded-lg flex items-center space-x-4">
                                    <Mail className="text-orange-500 w-6 h-6" />
                                    <div>
                                        <h3 className="font-semibold text-lg">Email Address</h3>
                                        <p className="text-gray-600">info@spacemaster.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Right Section - Form */}
                        <div>
                            <form className="space-y-8">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div className="relative">
                                        <input
                                            type="text"
                                            placeholder="Name"
                                            className="w-full p-3 border border-orange-200 rounded-lg pl-10"
                                        />
                                        <User className="w-5 h-5 text-gray-400 absolute left-3 top-3" />
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="tel"
                                            placeholder="Phone Number"
                                            className="w-full p-3 border border-orange-200 rounded-lg pl-10"
                                        />
                                        <Phone className="w-5 h-5 text-gray-400 absolute left-3 top-3" />
                                    </div>
                                </div>

                                <div className="relative">
                                    <input
                                        type="email"
                                        placeholder="Email Address"
                                        className="w-full p-3 border border-orange-200 rounded-lg pl-10"
                                    />
                                    <Mail className="w-5 h-5 text-gray-400 absolute left-3 top-3" />
                                </div>

                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder="Location of Your Plot"
                                        className="w-full p-3 border border-orange-200 rounded-lg pl-10"
                                    />
                                    <Briefcase className="w-5 h-5 text-gray-400 absolute left-3 top-3" />
                                </div>

                                <button
                                    type="submit"
                                    className="w-full bg-orange-500 text-white py-3 px-6 rounded-lg hover:bg-orange-600 transition-colors"
                                >
                                    Book Free Consultation
                                </button>
                            </form>
                        </div>
                    </div>
                </div >
            </div >













            {/* faq */}
            <section className="py-24 px-8 flex flex-col bg-orange-50 md:flex-row gap-12 w-full font-serif">
                {/* Title Section */}
                <div className="relative flex flex-col text-left basis-1/2 md:ml-16 px-10">
                    <p className="text-5xl sm:text-5xl font-normal mb-4 leading-tight text-gray-900">
                        Common Questions About House <br /> Construction Pricing

                    </p>

                    {/* Falling Balls Container */}
                    <div className="absolute top-0 left-0 w-full h-full">
                        <div className="ball ball-1"></div>
                        <div className="ball ball-2"></div>
                        <div className="ball ball-3"></div>
                        <div className="ball ball-4"></div>
                        <div className="ball ball-5"></div>
                    </div>


                </div>

                {/* FAQ Section */}
                <ul className="basis-1/2 px-4">
                    {faqs.map((faq, index) => (
                        <li key={index} className="border-t border-orange-500">
                            {/* FAQ Question */}
                            <button
                                className="relative flex items-center justify-between w-full py-5 text-left text-gray-800 font-medium transition-colors hover:text-gray-900 focus:outline-none"
                                aria-expanded={expandedIndex === index}
                                aria-controls={`faq-answer-${index}`}
                                onClick={() => toggleFAQ(index)}
                            >
                                <span className=''>{faq.question}</span>
                                <svg
                                    className={`w-5 h-5 transition-transform duration-300 ease-in-out ${expandedIndex === index ? "rotate-90" : "rotate-0"
                                        }`}
                                    viewBox="0 0 16 16"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect y="7" width="16" height="2" rx="1"></rect>
                                    <rect
                                        y="7"
                                        width="16"
                                        height="2"
                                        rx="1"
                                        className="transform origin-center rotate-90"
                                    ></rect>
                                </svg>
                            </button>

                            {/* FAQ Answer */}
                            <div
                                id={`faq-answer-${index}`}
                                className={`transition-all duration-300 ease-in-out overflow-hidden ${expandedIndex === index ? "max-h-40" : "max-h-0"
                                    }`}
                            >
                                <div className="py-4 text-gray-700">
                                    <p className="leading-relaxed">{faq.answer}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </section>

            <div className="bg-[#2c2420] bg-opacity-60 backdrop-blur-lg px-4 py-16 font-serif relative">
                {/* Background Image */}
                <div
                    className="absolute top-0 left-0 w-full h-full bg-cover bg-fixed bg-center"
                    style={{
                        backgroundImage: `linear-gradient(to bottom, 
            rgba(59, 48, 44, 0.7), 
            rgba(59, 48, 44, 0.7), 
            rgba(59, 48, 44, 0.7), 
            rgba(59, 48, 44, 0.8)),  
            url('/assets/images/handhome.png')`,
                        filter: "blur(0px)", // Adds the blur effect to the image.
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                ></div>

                {/* Content Section */}
                <div className="max-w-6xl mx-auto relative z-10">
                    {/* Heading */}
                    <h1 className="text-5xl sm:text-5xl font-normal mb-4 leading-tight text-gray-900 text-center text-orange-200 ">
                        Consult a Professional<br /> Construction Expert
                    </h1>

                    {/* Subtext */}


                    {/* Button or Contact */}
                    <div className="flex justify-center">
                        <a
                            href="/contact-us"
                            className="text-lg  px-6 py-3 bg-amber-700 hover:bg-orange-600 text-white rounded-md transition"
                        >
                            Contact Us
                        </a>
                    </div>
                </div>
            </div>

        </>

    );
}
