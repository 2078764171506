import React from 'react';


const Services = () => {


    return (
        <div className="bg-darkCoffee text-white mb-8 ">
            {/* Hero Section */}
            <div className="max-w-7xl mx-auto px-4 py-20">
                <div className="text-orange-400 font-medium text-sm mb-4  text-center">— SERVICES —</div>
                <h1 className="text-5xl font-serif mb-6 font-normal text-center">
                    OUR SERVICES
                </h1>
                <p className="text-silver text-center mb-8">
                    At Space Master Constructions, we bring your dream spaces to life with precision and care. Our expertise spans from innovative design to impeccable execution, ensuring every project meets the highest standards of quality and satisfaction.
                </p>


                {/* Services Grid */}
                <div className="grid md:grid-cols-2 gap-16 mb-20">
                    {/* Log Houses Section */}
                    <div className="flex flex-col items-end">
                        <div className="flex items-center gap-4 mb-6">
                            <span className="text-amber-400 opacity-80 text-2xl">01.</span>
                            <h2 className="text-3xl font-serif">House Construction</h2>
                        </div>
                        <p className="text-gray-400 mb-8 leading-relaxed">
                            We specialize in high-quality residential construction, ensuring that every project is built to exceed expectations. From modern single-family homes to multi-story residences, our team uses the finest materials and construction techniques to deliver exceptional results. With a focus on durability, energy efficiency, and aesthetic appeal.
                        </p>
                        <button className="border border-white px-6 py-3 hover:bg-amber-700 transition duration-300">
                            View projects
                        </button>
                    </div>



                    {/* Construction for Business Section */}
                    <div className="flex flex-col items-end">
                        <div className="flex items-center gap-4 mb-6">
                            <span className="text-amber-400 opacity-80 text-2xl">02.</span>
                            <h2 className="text-3xl font-serif">Construction for Business</h2>
                        </div>
                        <p className="text-gray-400 mb-8 leading-relaxed">
                            Building commercial spaces tailored to your business needs, we specialize in creating functional, modern, and scalable environments that support your operations. From office buildings to retail spaces and industrial facilities, we collaborate closely with clients to design and construct spaces that align with their brand, workflow, and growth objectives.
                        </p>
                        <button className="border border-white px-6 py-3 hover:bg-amber-700 transition duration-300">
                            View projects
                        </button>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Services;
