import React, { useState } from 'react';
import { Phone, Mail, User, Briefcase, } from 'lucide-react';
import Homeconst from '../components/Homeconst';

const Packages = () => {
    const [showDetails, setShowDetails] = useState({
        designs: false,
        structure: false,
        kitchen: false,
        bathroom: false,
        doors: false,
        painting: false,
        flooring: false,
        electrical: false,
        miscellaneous: false
    });
    const [selectedPackageLeft, setSelectedPackageLeft] = useState('basic');
    const [selectedPackageRight, setSelectedPackageRight] = useState('classic');

    const handlePackageSelect = (packageSide, selectedPackage) => {
        if (packageSide === 'left') {
            setSelectedPackageLeft(selectedPackage);
        } else {
            setSelectedPackageRight(selectedPackage);
        }
    };

    const packages = [
        { value: 'basic', label: '₹1,810/sq.ft - Basic Package' },
        { value: 'classic', label: '₹1,940/sq.ft - Classic Package' },
        { value: 'premium', label: '₹2,100/sq.ft - Premium Package' },
        { value: 'royal', label: '₹2,300/sq.ft - Royal Package' },
    ];

    const [city, setCity] = useState('Bengaluru');

    const toggleDetails = (item) => {
        setShowDetails((prevDetails) => ({
            ...prevDetails,
            [item]: !prevDetails[item]
        }));
    };

    const handleCityChange = (event) => {
        setCity(event.target.value);
    };

    const getCityComparisonData = (city) => {
        const data = {
            Bengaluru: {
                designs: {
                    basic: {
                        description: '',
                        subItems: [
                            'Architectural Layout | 2D',
                            'Basic Elevation',
                            'Structural Design',
                            '3D Elevation'
                        ]
                    },




                    classic: {
                        description: '',
                        subItems: [
                            'Architectural Layout | 2D',
                            'Basic Elevation',
                            'Structural Design',
                            '3D Elevation',
                            'Electrical Drawings',
                            'Plumbing Drawings'
                        ]
                    },
                    premium: {
                        description: '',
                        subItems: [
                            'Architectural Layout | 2D',

                            'Basic Elevation',

                            'Structural Design',

                            '3D Elevation',

                            'Electrical Drawings',

                            'Plumbing Drawings',
                        ]
                    },
                    royal: {
                        description: '',
                        subItems: [
                            'Architectural Layout | 2D',

                            '  Basic Elevation',

                            'Structural Design',

                            ' 3D Elevation',

                            ' Electrical Drawings',

                            ' Plumbing Drawings',

                            'Furniture Plan',
                        ]
                    },
                },
                structure: {
                    basic: {
                        description: '',
                        subItems: [
                            'Reinforcement Steel - Sunvik, Prime Gold, Kamadhenu, or comparable brands',
                            'Cement - Dalmia, Bharathi, or similar of 43/53 grade quality',
                            'Aggregates - Sizes 20mm and 40mm for construction use',
                            'Blocks - Premium-grade solid concrete blocks (6-inch and 4-inch sizes)',
                            'RCC Mix - M20 / M25, as per the structural engineers specifications',
                            'Ceiling Height - 10 feet (from finished floor level to the next finished floor level)',
                        ]
                    },
                    classic: {
                        description: '',
                        subItems: [
                            'Steel: Jindal or comparable high-quality reinforcement steel brands',
                            'Cement: Dalmia, Bharathi, or similar, with 43 or 53-grade specifications',
                            'Aggregates: Crushed stone aggregates of 20mm and 40mm sizes',
                            'Blocks: Premium solid concrete blocks in 6-inch and 4-inch dimensions',
                            'RCC Mix: M20 / M25 grade, as specified by the structural engineer',
                            'Ceiling Height: 10 feet (measured from finished floor to finished floor)',
                        ]
                    },
                    premium: {
                        description: '',
                        subItems: [
                            'Steel: Jindal or similar trusted brands of reinforcement steel',
                            'Cement: ACC, Ultratech, or equivalent, meeting 43 or 53-grade standards',
                            'Aggregates: Graded crushed stones of 20mm and 40mm sizes',
                            'Blocks: Durable solid concrete blocks, available in 6-inch and 4-inch sizes',
                            'RCC Mix: M20 / M25 grade from ACC, Ultratech, or as advised by the structural engineer',
                            'Ceiling Height: 10 feet (from finished floor level to finished floor level)',
                        ]
                    },
                    royal: {
                        description: '',
                        subItems: [
                            ' Steel: Jindal or comparable high-grade steel brands',
                            'Cement: ACC, Ultratech, or other equivalent options meeting 43 or 53-grade specifications',
                            'Aggregates: Quality crushed stones in 20mm and 40mm sizes',
                            'Blocks: Premium solid concrete blocks in standard sizes of 6 inches and 4 inches',
                            'RCC Design Mix: M20 / M25 grade concrete by ACC, Ultratech, or as per structural engineer recommendations',
                            'Ceiling Height: 10 feet (measured from finished floor to finished floor level)',
                        ]
                    },
                },




                kitchen: {
                    basic: {
                        description: '',
                        subItems: [
                            ' Steel: Jindal or comparable high-grade steel brands',
                            'Cement: ACC, Ultratech, or other equivalent options meeting 43 or 53-grade specifications',
                            'Aggregates: Quality crushed stones in 20mm and 40mm sizes',
                            'Blocks: Premium solid concrete blocks in standard sizes of 6 inches and 4 inches',
                            'RCC Design Mix: M20 / M25 grade concrete by ACC, Ultratech, or as per structural engineer recommendations',
                            'Ceiling Height: 10 feet (measured from finished floor to finished floor level)',
                        ]
                    },
                    classic: 'Kitchen 2 (Bengaluru)',
                    premium: 'Kitchen 3 (Bengaluru)',
                    royal: 'Kitchen 4 (Bengaluru)',
                },
                bathroom: {
                    basic: 'Bathroom 1 (Bengaluru)',
                    classic: 'Bathroom 2 (Bengaluru)',
                    premium: 'Bathroom 3 (Bengaluru)',
                    royal: 'Bathroom 4 (Bengaluru)',
                },
                doors: {
                    basic: 'Doors 1 (Bengaluru)',
                    classic: 'Doors 2 (Bengaluru)',
                    premium: 'Doors 3 (Bengaluru)',
                    royal: 'Doors 4 (Bengaluru)',
                },
                painting: {
                    basic: 'Painting 1 (Bengaluru)',
                    classic: 'Painting 2 (Bengaluru)',
                    premium: 'Painting 3 (Bengaluru)',
                    royal: 'Painting 4 (Bengaluru)',
                },
                flooring: {
                    basic: 'Flooring 1 (Bengaluru)',
                    classic: 'Flooring 2 (Bengaluru)',
                    premium: 'Flooring 3 (Bengaluru)',
                    royal: 'Flooring 4 (Bengaluru)',
                },
                electrical: {
                    basic: 'Electrical 1 (Bengaluru)',
                    classic: 'Electrical 2 (Bengaluru)',
                    premium: 'Electrical 3 (Bengaluru)',
                    royal: 'Electrical 4 (Bengaluru)',
                },
                miscellaneous: {
                    basic: 'Miscellaneous 1 (Bengaluru)',
                    classic: 'Miscellaneous 2 (Bengaluru)',
                    premium: 'Miscellaneous 3 (Bengaluru)',
                    royal: 'Miscellaneous 4 (Bengaluru)',
                }
            },
            Chennai: {
                designs: {
                    basic: {
                        description: '',
                        subItems: [
                            'Architectural Layout | 4D',
                            'Basic Elevation',
                            'Structural Design',
                            '3D Elevation'
                        ]
                    },




                    classic: {
                        description: '',
                        subItems: [
                            'Architectural Layout | 2D',
                            'Basic Elevation',
                            'Structural Design',
                            '3D Elevation',
                            'Electrical Drawings',
                            'Plumbing Drawings'
                        ]
                    },
                    premium: {
                        description: '',
                        subItems: [
                            'Architectural Layout | 2D',

                            'Basic Elevation',

                            'Structural Design',

                            '3D Elevation',

                            'Electrical Drawings',

                            'Plumbing Drawings',
                        ]
                    },
                    royal: {
                        description: '',
                        subItems: [
                            'Architectural Layout | 2D',

                            '  Basic Elevation',

                            'Structural Design',

                            ' 3D Elevation',

                            ' Electrical Drawings',

                            ' Plumbing Drawings',

                            'Furniture Plan',
                        ]
                    },

                },
                structure: {
                    basic: 'Structure 1 (Chennai)',
                    classic: 'Structure 2 (Chennai)',
                    premium: 'Structure 3 (Chennai)',
                    royal: 'Structure 4 (Chennai)',
                },
                kitchen: {
                    basic: 'Kitchen 1 (Chennai)',
                    classic: 'Kitchen 2 (Chennai)',
                    premium: 'Kitchen 3 (Chennai)',
                    royal: 'Kitchen 4 (Chennai)',
                },
                bathroom: {
                    basic: 'Bathroom 1 (Chennai)',
                    classic: 'Bathroom 2 (Chennai)',
                    premium: 'Bathroom 3 (Chennai)',
                    royal: 'Bathroom 4 (Chennai)',
                },
                doors: {
                    basic: 'Doors 1 (Chennai)',
                    classic: 'Doors 2 (Chennai)',
                    premium: 'Doors 3 (Chennai)',
                    royal: 'Doors 4 (Chennai)',
                },
                painting: {
                    basic: 'Painting 1 (Chennai)',
                    classic: 'Painting 2 (Chennai)',
                    premium: 'Painting 3 (Chennai)',
                    royal: 'Painting 4 (Chennai)',
                },
                flooring: {
                    basic: 'Flooring 1 (Chennai)',
                    classic: 'Flooring 2 (Chennai)',
                    premium: 'Flooring 3 (Chennai)',
                    royal: 'Flooring 4 (Chennai)',
                },
                electrical: {
                    basic: 'Electrical 1 (Chennai)',
                    classic: 'Electrical 2 (Chennai)',
                    premium: 'Electrical 3 (Chennai)',
                    royal: 'Electrical 4 (Chennai)',
                },
                miscellaneous: {
                    basic: 'Miscellaneous 1 (Chennai)',
                    classic: 'Miscellaneous 2 (Chennai)',
                    premium: 'Miscellaneous 3 (Chennai)',
                    royal: 'Miscellaneous 4 (Chennai)',
                }
            },
            Hyderabad: {
                designs: {
                    basic: 'Design 1 (Hyderabad)',
                    classic: 'Design 2 (Hyderabad)',
                    premium: 'Design 3 (Hyderabad)',
                    royal: 'Design 4 (Hyderabad)',
                },
                structure: {
                    basic: 'Structure 1 (Hyderabad)',
                    classic: 'Structure 2 (Hyderabad)',
                    premium: 'Structure 3 (Hyderabad)',
                    royal: 'Structure 4 (Hyderabad)',
                },
                kitchen: {
                    basic: 'Kitchen 1 (Hyderabad)',
                    classic: 'Kitchen 2 (Hyderabad)',
                    premium: 'Kitchen 3 (Hyderabad)',
                    royal: 'Kitchen 4 (Hyderabad)',
                },
                bathroom: {
                    basic: 'Bathroom 1 (Hyderabad)',
                    classic: 'Bathroom 2 (Hyderabad)',
                    premium: 'Bathroom 3 (Hyderabad)',
                    royal: 'Bathroom 4 (Hyderabad)',
                },
                doors: {
                    basic: 'Doors 1 (Hyderabad)',
                    classic: 'Doors 2 (Hyderabad)',
                    premium: 'Doors 3 (Hyderabad)',
                    royal: 'Doors 4 (Hyderabad)',
                },
                painting: {
                    basic: 'Painting 1 (Hyderabad)',
                    classic: 'Painting 2 (Hyderabad)',
                    premium: 'Painting 3 (Hyderabad)',
                    royal: 'Painting 4 (Hyderabad)',
                },
                flooring: {
                    basic: 'Flooring 1 (Hyderabad)',
                    classic: 'Flooring 2 (Hyderabad)',
                    premium: 'Flooring 3 (Hyderabad)',
                    royal: 'Flooring 4 (Hyderabad)',
                },
                electrical: {
                    basic: 'Electrical 1 (Hyderabad)',
                    classic: 'Electrical 2 (Hyderabad)',
                    premium: 'Electrical 3 (Hyderabad)',
                    royal: 'Electrical 4 (Hyderabad)',
                },
                miscellaneous: {
                    basic: 'Miscellaneous 1 (Hyderabad)',
                    classic: 'Miscellaneous 2 (Hyderabad)',
                    premium: 'Miscellaneous 3 (Hyderabad)',
                    royal: 'Miscellaneous 4 (Hyderabad)',
                }
            }
        };

        return data[city];
    };

    const cityData = getCityComparisonData('Bengaluru');
    console.log(cityData);

    return (
        <div>
            <section className="relative isolate py-16 sm:py-24 lg:py-32 font-serif">
                {/* Background */}
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{
                        backgroundImage: `linear-gradient(to bottom, 
            rgba(59, 48, 44, 0.9), 
            rgba(59, 48, 44, 0.9), 
            rgba(59, 48, 44, 0.7), 
            rgba(59, 48, 44, 0.8)),  
            url('/assets/images/handhome.png')`,
                        filter: "blur(0px)", // Adds the blur effect to the image.
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                ></div>
                <div className="relative max-w-7xl mx-auto px-7 lg:px-8 flex items-center justify-center h-full">
                    {/* "Get in Touch" Section */}
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center text-left lg:text-left">
                        {/* Content Section */}

                        {/* Repeat Content Section */}
                        <div className=''>
                            <h1 className="text-6xl text-orange-200 mt-12 font-black">Packages</h1>

                            <p className="text-lg text-white leading-relaxed mt-8">
                                Explore our thoughtfully designed packages crafted to align with your unique preferences, offering innovative solutions for modern construction needs.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Homeconst />


            <div className="bg-orange-50 min-h-screen">
                <div className="w-full max-w-6xl mx-auto bg-orange-50  p-6 font-serif">
                    {/* Title */}
                    <div className="mb-6">
                        <h2 className="text-3xl md:text-5xl text-gray-800 text-left">Compare Packages</h2>
                    </div>

                    {/* City Selection */}
                    <div className="mb-6">
                        <label htmlFor="city" className="font-medium text-lg">Select City:</label>
                        <select
                            id="city"
                            value={city}
                            onChange={handleCityChange}
                            className="w-full md:w-auto max-w-xs  bg-orange-100 mt-2 md:ml-4 py-2 px-4  rounded-md"
                        >
                            <option value="Bengaluru">Bengaluru</option>
                            <option value="Chennai">Chennai</option>
                            <option value="Hyderabad">Hyderabad</option>
                        </select>
                    </div>

                    {/* Package Selection */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                        {/* Left Package */}
                        <div>
                            <p className="font-medium text-sm ">Select Left Package:</p>
                            <select
                                value={selectedPackageLeft}
                                onChange={(e) => handlePackageSelect('left', e.target.value)}
                                className="w-full md:w-auto max-w-xs py-2 px-4 mt-2 bg-orange-100 rounded-md"
                            >
                                {packages.map((pkg) => (
                                    <option
                                        key={pkg.value}
                                        value={pkg.value}
                                        disabled={selectedPackageRight === pkg.value}
                                    >
                                        {pkg.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {/* Right Package */}
                        <div>
                            <p className="font-medium text-sm">Select Right Package:</p>
                            <select
                                value={selectedPackageRight}
                                onChange={(e) => handlePackageSelect('right', e.target.value)}
                                className="w-full md:w-auto max-w-xs py-2 px-4 mt-2 bg-orange-100 rounded-md"
                            >
                                {packages.map((pkg) => (
                                    <option
                                        key={pkg.value}
                                        value={pkg.value}
                                        disabled={selectedPackageLeft === pkg.value}
                                    >
                                        {pkg.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    {/* Package Comparison */}
                    <div className="space-y-2 border-t  border-gray-400 pt-6 font-serif">
                        {['designs', 'structure', 'kitchen', 'bathroom', 'doors', 'painting', 'flooring', 'electrical', 'miscellaneous'].map((item) => (
                            <div key={item}>
                                <div className="flex justify-between items-center py-4 border-b border-gray-300">
                                    <p className="font-medium capitalize">{item.replace(/_/g, ' ')}</p>
                                    <button
                                        className="text-amber-700 text-3xl hover:underline"
                                        onClick={() => toggleDetails(item)}
                                    >
                                        {showDetails[item] ? '-' : '+'}
                                    </button>
                                </div>

                                {/* Package Details */}
                                {showDetails[item] && (
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 text-sm text-gray-600 mt-4">
                                        {/* Left Package Details */}
                                        {selectedPackageLeft && (
                                            <div>
                                                <p className="font-medium text-amber-700 text-lg">
                                                    {selectedPackageLeft.charAt(0).toUpperCase() + selectedPackageLeft.slice(1)}
                                                </p>
                                                <p>{cityData[item][selectedPackageLeft].description}</p>
                                                <ul className="list-disc pl-6 space-y-4 text-darkCofee">
                                                    {cityData[item][selectedPackageLeft].subItems.map((subItem, index) => (
                                                        <li key={index}>{subItem}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}

                                        {/* Right Package Details */}
                                        {selectedPackageRight && (
                                            <div>
                                                <p className="font-medium text-amber-700 text-lg ">
                                                    {selectedPackageRight.charAt(0).toUpperCase() + selectedPackageRight.slice(1)}
                                                </p>
                                                <p>{cityData[item][selectedPackageRight].description}</p>
                                                <ul className="list-disc pl-6 space-y-4 text-darkCofee">
                                                    {cityData[item][selectedPackageRight].subItems.map((subItem, index) => (
                                                        <li key={index}>{subItem}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>




            < div
                className="bg-orange-50 bg-cover bg-center p-4 md:p-8"

            >
                {/* Main Contact Section */}
                < div className="max-w-7xl mx-auto p-8 bg-orange-50 rounded-lg " >
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">

                        {/* Left Section */}
                        <div className="flex flex-col justify-between">
                            <div>
                                <div className="text-orange-400 font-medium text-sm mb-4">— CONTACT US —</div>
                                <h2 className="text-5xl font-serif mb-6 font-normal mb-4">Talk To Our Experts</h2>
                                <p className="text-gray-600 mb-6">
                                    Connect with our team of professionals to discuss your needs and find tailored solutions. Rest assured, we'll provide the expertise and guidance you deserve.
                                </p>
                            </div>
                            <div className="space-y-2">
                                <div className="bg-orange-50 p-2 rounded-lg flex items-center space-x-4">
                                    <Phone className="text-orange-500 w-6 h-6" />
                                    <div>
                                        <h3 className="font-semibold text-lg">Phone Number</h3>
                                        <p className="text-gray-600">+91 1234567890</p>
                                    </div>
                                </div>
                                <div className="bg-orange-50 p-2 rounded-lg flex items-center space-x-4">
                                    <Mail className="text-orange-500 w-6 h-6" />
                                    <div>
                                        <h3 className="font-semibold text-lg">Email Address</h3>
                                        <p className="text-gray-600">info@spacemaster.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Right Section - Form */}
                        <div>
                            <form className="space-y-8">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div className="relative">
                                        <input
                                            type="text"
                                            placeholder="Name"
                                            className="w-full p-3 border border-orange-200 rounded-lg pl-10"
                                        />
                                        <User className="w-5 h-5 text-gray-400 absolute left-3 top-3" />
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="tel"
                                            placeholder="Phone Number"
                                            className="w-full p-3 border border-orange-200 rounded-lg pl-10"
                                        />
                                        <Phone className="w-5 h-5 text-gray-400 absolute left-3 top-3" />
                                    </div>
                                </div>

                                <div className="relative">
                                    <input
                                        type="email"
                                        placeholder="Email Address"
                                        className="w-full p-3 border border-orange-200 rounded-lg pl-10"
                                    />
                                    <Mail className="w-5 h-5 text-gray-400 absolute left-3 top-3" />
                                </div>

                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder="Location of Your Plot"
                                        className="w-full p-3 border border-orange-200 rounded-lg pl-10"
                                    />
                                    <Briefcase className="w-5 h-5 text-gray-400 absolute left-3 top-3" />
                                </div>

                                <button
                                    type="submit"
                                    className="w-full bg-orange-500 text-white py-3 px-6 rounded-lg hover:bg-orange-600 transition-colors"
                                >
                                    Book Free Consultation
                                </button>
                            </form>
                        </div>
                    </div>
                </div >
            </div >

        </div >


    );
};

export default Packages;
