import React from 'react';
import Sidebar from '../pages/Sidebar';
import Dheader from './Dheader';
import Dfooter from './Dfooter';
import Packages1 from '../pages/Packages1';

const Packages3 = () => {
    return (


        <div className="dashboard-container  bg-white">
            <Sidebar /> {/* Render Sidebar here */}
            <div className="flex-1 p-6">
                {/* Header starts after the sidebar */}
                <div className="md:ml-64"> {/* Adjust the margin based on the sidebar's width */}
                    <Dheader />
                </div>
                <div className="md:ml-64">
                    <Packages1 />
                </div>
                <div className='md:ml-64'>
                    <Dfooter />
                </div>
            </div>
        </div>
    );
};

export default Packages3;
